.market-search-wrapper {
  min-height: 100vh;
  height: 100%;
  position: relative;
  .cart-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
