@import "scss/mixins";

.search-list {
  background-color: #ffffff;
  &.suggest-search {
    height: calc(100vh - 56px - 27px);
    overflow-y: scroll;
  }
  &.search-history-list {
    position: unset;
    bottom: unset;
    margin-bottom: 8px;
    height: auto;
    flex-grow: 1;

    position: relative;

    .bgr-illustration {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.1;
    }

    .search-history-list__heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      background: #ececec;
      padding: 10px 16px;

      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.3px;
      color: #000000;

      .search-history-list__label {
        font-weight: 600;
      }

      .btn-delete-search-history {
        font-weight: 400;
      }
    }
  }
  .search-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 65px;
    border: solid 1px #f2f2f2;
    .search-item__info {
      display: flex;
      align-items: center;
      .search-item-name {
        margin-left: 5px;
        margin-right: 5px;
        @include font(400, 14px, #000000);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }
    .search-item__icon {
      .store-logo {
        margin-right: 10px;
      }
    }
  }
}

.history-keypopular-group::-webkit-scrollbar {
  display: none;
}
.history-keypopular-group {
  background-color: #ebedef;
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  height: auto;
  object-fit: cover;
  overflow-y: scroll;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
