@import "scss/mixins";
@import "scss/variables";

.category-wrapper::-webkit-scrollbar {
  display: none;
}

.category-wrapper {
  padding: 16px 0;
  overflow: auto !important;
  background-color: $color-white;
  margin: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  &.hidden-x-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .category-padding {
    padding: 0 0 30px 0;
  }

  .category-row {
    width: 100%;
    display: flex;
    // flex: 1 1 1 1 1;
    // display: flex;
    justify-content: flex-start;
    .option {
      display: flex;
      // flex: 1 0 22%;
      border-radius: 8px;
      flex-direction: column;
      justify-self: center;
      // justify-content: center;
      align-items: center;
      h3 {
        color: #373737;
        margin: 0px;
        width: 80%;
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      & > img {
        height: 32px;
      }
    }
  }
}

@media only screen and (max-width: 395px) {
  .category-wrapper {
    .category-row {
      display: flex;
      width: 100%;
      .option {
        // flex: 1 0 22%;
      }
    }
  }
}
