@import "scss/variables";
@import "scss/mixins.scss";

.store-info-card__tags {
  display: flex;
  align-items: center;
  list-style: none;
  max-width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  // position: relative;

  &::-webkit-scrollbar {
    background: transparent;
    /* make scrollbar transparent */
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }

  margin-bottom: 20px;

  .promotion-item {
    background-color: #ffffff;
    padding: 7px;
    position: relative;
    border-radius: 3px;
    border-left: 3px solid $color-ucla_gold;
    min-width: 200px;
  }

  & > :not(:first-child) {
    margin-left: 6px;
  }

  & > :last-child {
    margin-right: 12px;
    max-width: calc(800px - 2 * 12px); // cho case chi co duy nhat 1 tag
  }

  .btn-prev-scroll {
    visibility: hidden;
  }
  .btn-next-scroll {
    visibility: hidden;
  }
}

@include minW(800px) {
  // &:hover {
  //   .btn-prev-scroll {
  //     visibility: visible;
  //     display: inline-block;
  //     transition: all 100s ease-in-out;
  //   }

  //   .btn-next-scroll {
  //     visibility: visible;
  //     display: inline-block;
  //     transition: all 100s ease-in-out;
  //   }
  // }

  .btn-next-scroll {
    background: 0 0;
    border: 0;
    justify-self: center;
    outline: 0;
    right: 0;
    position: absolute;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: none;
    width: 52px;
    height: 52px;
  }

  .btn-prev-scroll {
    background: 0 0;
    border: 0;
    justify-self: center;
    outline: 0;
    left: 0;
    position: absolute;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: none;
    width: 52px;
    height: 52px;
  }

  .item-image {
    height: 500px;
  }

  .see-more-image {
    z-index: 100;
    position: absolute;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    top: 260px;
    padding: 9px;
    border-radius: 7px;
    color: #fff;
    @include font(600, 12px, #fff);
  }

  .see-more-image:hover {
    cursor: pointer;
  }

  .icon-album {
    margin-right: 5px;
  }
}

.vertical-dashed-separator {
  border-left: 1px dashed #dedede;
}

.button-apply {
  border: none;
  width: 8ch;
  outline: none;
  background-color: #ffffff;
}

.dotted-right-border {
  position: absolute;
  // right: -7px;
  // bottom: 10px;
  // top: 10px;
  // border-right: 10px dotted #ebedef;
  right: -5px;
  bottom: 7px;
  top: 7px;
  border-right: 7px dotted #ebedef;
}

.promotion-item-applied {
  background-color: $color-cosmic_latte;
  // retrieve 1px for border, except the left border
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 6px;
  border: 1px solid $color-ucla_gold;

  border-left: 3px solid $color-ucla_gold;

  .vertical-dashed-separator {
    border-left: 1px dashed $color-ucla_gold;
  }

  .button-apply {
    background-color: $color-cosmic_latte;
  }

  .dotted-right-border {
    border-right-color: $color-ucla_gold;
  }

  .dotted-right-border-cover {
    // right: -8px;
    right: -6px;
    border-right-color: $bgr-app;
  }

  .redundant-dotted-cover {
    width: 10px;
    position: absolute;
    right: -11px;
    bottom: 0px;
    top: 0px;
    background-color: $bgr-app;
  }
}

// promotion page
.promotion-page {
  .promo-list {
    & > :not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}

.promotion-list--horizontal {
  .promotion-item {
    width: 300px;
    max-width: 80vw;
  }
}
