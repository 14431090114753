@import "scss/variables";

.suggested-shipping-discount-tag {
  display: inline-block;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 7px;
}
