@import "scss/mixins";
@import "scss/variables";

.store-wrapper {
  padding: 10px 10px;
  .store-wrapper_header {
    @include midFlex(space-between, center);
    margin: 0px 0px 10px 0px;
    .label {
      font-weight: 600;
      font-size: 16px;
      color: $color-black;
    }
  }
  .store-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .button-see-more {
    width: 100%;
    background: white;
    @include midFlex(center, center);
    padding: 10px 0;
    border-radius: 8px;
  }
}

.rating-content__see-more {
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    @include font(600, 12px, #ffb600);
    margin-right: 4px;
  }
}
