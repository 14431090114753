@import "scss/variables";
@import "scss/mixins";

.set-delivery-info__wrapper {
  padding: 16px 8px;
  margin-bottom: 77px; // offset for fixed bottom button

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 14px;
    font-weight: 500;
    color: #b8b8b8;

    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b8b8b8;

    font-size: 14px;
    font-weight: 500;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b8b8b8;

    font-size: 14px;
    font-weight: 500;
  }

  .shipping-info__wrapper {
    background: #ffffff;
    padding: 1rem;
  }

  .error-message {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.233333px;

    color: #f03939;

    margin-top: 5px;
  }

  .delivery-provider-wrapper {
    background: #fff;
    padding: 16px;
    border-radius: 7px;
    margin-top: 16px;

    .shipping-content {
      .shipping-fee {
        @include midFlex(space-between, center);
        .shipping-fee_title {
          color: black;
          font-weight: 500;
        }
      }
      .shipping-fee-note {
        @include midFlex(space-between, center);
        .shipping-fee-note_title {
          display: flex;
          .shipping-fee-note_title-text {
            color: rgb(88, 171, 0);
            font-weight: 600;
            margin-right: 5px;
          }
        }
        .shipping-fee-note_title-value-text {
          color: rgb(88, 171, 0);
          font-weight: 600;
        }
      }
      .shipping-description {
        .shipping-description-text {
          background-color: rgb(255, 249, 234);
          color: rgb(250, 100, 0);
          padding: 0 5px;
          font-size: 12px;
        }
      }

      .shipping-dashed {
        border-bottom: dashed 1px #d6d6d6;
        padding-top: 10px;
      }
    }

    .delivery-estimate-wrapper {
      border-bottom: dashed 1px #d6d6d6;
      padding-bottom: 8px;
      margin-bottom: 10px;

      .delivery-estimate-content {
        display: flex;
        align-items: center;

        .icon-estimate {
          width: 16px;
          height: 15px;
          margin-right: 8px;
        }

        .text-estimate {
          color: black;
        }
      }
    }
  }
}

.button-bottom-screen {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background: #fff;

  padding: 1rem;

  button {
    border: none;
    outline: none;
    border-radius: 20.5px;
    width: 100%;
    background: $color-linear;
    padding: 12px 0px;

    color: #fff;
    font-size: 14px;
    font-weight: 700;

    &:disabled {
      background: $sub-color;
    }
  }
}
