@import "scss/variables.scss";
@import "scss/mixins.scss";

@import "scss/fontWeights.scss";
@import "scss/fontSizes.scss";
@import "scss/colors.scss";
@import "scss/backgroundColors.scss";
@import "scss/icons.scss";

.DateRangePicker_picker {
  z-index: 2 !important;
}
.DateInput_fang {
  z-index: 3 !important;
}

.DateInput_input {
  font-size: 1rem !important;
  padding: 5px !important;
}

// custom UI

@font-face {
  font-family: "Avenir-Next";
  font-weight: 400;
  src: url(./../assets/fonts/AvenirNext-Regular.ttf);
}

@font-face {
  font-family: "Avenir-Next";
  font-weight: 500;
  src: url(./../assets/fonts/AvenirNext-Medium.ttf);
}

@font-face {
  font-family: "Avenir-Next";
  font-weight: 600;
  src: url(./../assets/fonts/AvenirNext-DemiBold.ttf);
}

@font-face {
  font-family: "Avenir-Next";
  font-weight: 700;
  src: url(./../assets/fonts/AvenirNext-Bold.ttf);
}

* {
  font-family: "Avenir-Next";
}

body {
  background-color: $bgr-body;
}

.btn {
  border-radius: 7px;
}
.c-main {
  padding-top: 0;
}
.c-header {
  background-image: none;
  background-color: transparent;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  .username {
    @include font(500, 16px, #ffffff);
  }
  .back-link {
    @include font(600, 18px, #ffffff);
  }
  .name-page {
    @include font(600, 18px, #ffffff);
  }
  .head-page {
    @include font(800, 20px, #ffffff);
    letter-spacing: 0.025em;
  }
}

.btn-warning {
  background-image: url("../img/btn-warning.png");
  background-color: orange;
  background-size: cover;
  background-position: bottom;
  @include font(600, 18px, #ffffff);
  &.disabled {
    background-color: rgb(211, 211, 211);
    background-image: none;
    @include font(600, 18px, rgb(179, 179, 179));
    &:hover {
      color: rgb(179, 179, 179);
    }
  }
  &:hover {
    color: #ffffff;
  }
}
.group-btn-inline {
  .btn {
    min-width: 155px;
    height: 45px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: none !important;
    &.btn-warning {
      @include font(600, 18px, #ffffff);
      &.disabled {
        background-color: rgb(211, 211, 211);
        background-image: none;
        @include font(600, 18px, rgb(179, 179, 179));
        &:hover {
          @include font(600, 18px, rgb(179, 179, 179));
        }
      }
      &:hover {
        @include font(600, 18px, #ffffff);
      }
    }
    &.btn-outline-warning {
      &:hover {
        @include font(600, 18px, #ffffff);
      }
    }
  }
}
.has-headerBgr {
  background-image: url("../assets/img/bgrheader.png");
  background-size: cover;
  background-position: bottom;
}
.bgr-home {
  // background-image: url("../img/bgr-home.png");
  background-size: cover;
  background-position: bottom;
  @include font(400, 18px, #ffffff);
  position: fixed;

  .c-header {
    background-image: none;
    background-color: transparent;
    padding-top: 25px;
    padding-bottom: 25px;
    .username {
      @include font(500, 16px, #ffffff);
    }
  }
}
.icon-arrow::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid #ffffff;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.container-fluid {
  max-width: 1260px;
  margin: 0 auto;
  padding-right: 60px !important;
  padding-left: 60px !important;
}
.title-page {
  @include font(600, 25px, rgb(21, 21, 21));
  margin-bottom: 43px;
  text-align: center;
}
// home
.welcome-text {
  @include font(800, 36px, #ffffff);
  letter-spacing: 0.046em;
  text-shadow: 0 0 2px #0000004d;
}
.list-control {
  margin: 0;
  padding: 20px;
  li {
    margin-bottom: 30px;
    .header-contol {
      @include font(400, 22px, #ffffff);
      letter-spacing: 0.046em;
      text-shadow: 0 0 2px #0000004d;
    }
  }
  .block-item {
    background-color: #ffffff;
    width: 508px;
    margin-left: -20px;
    padding: 15px 25px;
    border-radius: 12px;
    @include font(500, 20px, rgb(21, 21, 21));
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .text-block {
      p {
        margin-bottom: 0;
      }
      span {
        @include font(400, 14px, rgb(155, 155, 155));
      }
    }
  }
  .icon-user {
    @include imgIcon(28px, 28px, "../img/user-add.svg");
    margin-right: 24px;
  }
  .add-coin {
    @include imgIcon(28px, 28px, "../img/add-coin.svg");
    margin-right: 24px;
  }
  .his-coin {
    @include imgIcon(28px, 28px, "../img/his-coin.svg");
    margin-right: 24px;
  }
  .link-card {
    @include imgIcon(24px, 24px, "../img/link-card.svg");
    margin-right: 24px;
  }
  .cancel-link-card {
    @include imgIcon(24px, 24px, "../img/cancel-link-card.svg");
    margin-right: 24px;
  }
}

// register
.block-inner {
  background-color: #ffffff;
  border-radius: 12px;
  max-width: 1040px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 25px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05) !important;
}
.form-register {
  background-color: #ffffff;
  border-radius: 12px;
  max-width: 1040px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 25px;
  .img-regis {
    max-height: 200px;
  }
  .text-note {
    @include font(500, 13px, rgb(78, 78, 78));
  }
}

// history
.pagination.justify-content-start {
  justify-content: flex-end !important;
}
.sumUtop {
  text-align: center;
  margin-top: -42px;
  @include font(400, 14px, rgb(21, 21, 21));
  .numberU {
    @include font(600, 14px, rgb(21, 21, 21));
    padding-left: 10px;
  }
}
// topup
.topup-page {
  .container {
    max-width: 1070px;
  }
  .title-page {
    padding-top: 30px;
  }
  .step-progress {
    width: 450px;
    margin: auto;
    @include font(600, 14px, rgb(21, 21, 21));
    position: relative;
    &::before {
      content: "";
      width: 60%;
      border-top: 1px dashed rgb(155, 155, 155);
      position: absolute;
      left: -20px;
      right: 0;
      bottom: 0;
      top: 20px;
      margin: auto;
    }
    .step-item {
      .icon-number {
        display: inline-block;
        width: 40px;
        height: 40px;
        background-image: url("../img/btn-warning.png");
        background-size: contain;
        background-position: center;
        border-radius: 50%;
        position: relative;
        margin-bottom: 8px;
        &::before {
          content: attr(data-number);
          width: 40px;
          height: 40px;
          background-color: #ffffff;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          margin: auto;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          -ms-transform: scale(0.9, 0.9); /* IE 9 */
          transform: scale(0.9, 0.9); /* Standard syntax */
          @include font(600, 14px, rgb(21, 21, 21));
        }
      }
      &.step-accept {
        @include font(600, 14px, rgb(195, 195, 195));
        .icon-number {
          background-color: rgb(195, 195, 195);
          background-image: none;
          &::before {
            background: rgb(195, 195, 195) url("../img/tick.svg") no-repeat
              center/21px 14px;
          }
        }
      }
      &.disabled {
        @include font(600, 14px, rgb(211, 211, 211));
        .icon-number {
          background-color: rgb(211, 211, 211);
          background-image: none;
          &::before {
            @include font(600, 14px, rgb(211, 211, 211));
          }
        }
      }
    }
  }
  .blockquote {
    border-left: 4px solid #ffb600;
    border-radius: 8px;
    background-color: #ffffff;
    @include font(400, 12px, rgb(21, 21, 21));
    padding: 8px 35px 8px 10px;
    margin-bottom: 0;
    position: relative;
    p {
      margin-bottom: 0;
    }
    span {
      font-weight: 500;
    }
    .icon-refresh {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      @include imgIcon(30px, 30px, "../img/refresh.svg");
      background-size: 50% !important;
      box-shadow: none !important;
    }
  }
  .name-phone {
    @include font(400, 14px, rgb(155, 155, 155));
    position: relative;
    padding-left: 16px;
    margin-top: 10px;
    display: inline-block;
    &::before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: rgb(88, 202, 48);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .coin-utop {
    @include font(400, 12px, rgb(163, 163, 163));
    margin-top: 10px;
    margin-bottom: 0;
    span {
      @include font(500, 14px, rgb(21, 21, 21));
    }
  }

  .step-2 {
    .card-header {
      strong {
        @include font(500, 16px, rgb(21, 21, 21));
      }
    }
    .card-footer {
      @include font(600, 14px, rgb(21, 21, 21));
      small {
        @include font(400, 12px, rgb(155, 155, 155));
        font-style: italic;
        b {
          @include font(400, 12px, rgb(21, 21, 21));
          font-style: italic;
        }
      }
    }
    .list-group {
      .list-group-item {
        border: 1px dashed rgb(211, 211, 211);
        @include font(400, 14px, rgb(155, 155, 155));
        border-left: 0;
        border-right: 0;
        padding: 10px 0;
        span {
          @include font(600, 14px, rgb(21, 21, 21));
          p,
          strong {
            font-weight: 400;
          }
        }
        &:first-child {
          border-top: 0;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
.link-card-page {
  .btn-linkCard {
    @include font(400, 16px, #ffffff);
    border-radius: 8px;
    padding: 10px 20px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      @include imgIcon(28px, 28px, "../img/arrow-right.png");
      right: 20px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &:hover {
      text-decoration: none;
    }
  }
}
.note-block {
  border: 1px solid #ffb600;
  padding: 10px;
  position: relative;
  text-align: left;
  padding-left: 40px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 12px;
  &::before {
    content: "";
    position: absolute;
    @include imgIcon(8px, 8px, "../img/btn-warning.png");
    background-size: cover;
    border-radius: 50%;
    left: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  &.has-icon {
    text-align: center;
    @include font(400, 16px, rgb(21, 21, 21));
    &::before {
      display: none;
    }
    .tick-blue {
      @include imgIcon(20px, 15px, "../img/tick-blue.svg");
      margin-right: 10px;
    }
  }
}
.has-right {
  position: relative;
  &:after {
    content: attr(data-text);
    position: absolute;
    right: 16px;
    bottom: 0;
    top: 0;
    margin: auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include font(400, 18px, rgb(163, 163, 163));
  }
  input {
    padding-right: 70px;
  }
}
.topup-success-page {
  .block-inner {
    max-width: 685px;
  }
  @-webkit-keyframes pledge {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes pledge {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes pledge {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes pledge {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes shake-animation {
    0% {
      transform: translate(0, 0);
    }
    1.78571% {
      transform: translate(5px, 0);
    }
    3.57143% {
      transform: translate(0, 0);
    }
    5.35714% {
      transform: translate(5px, 0);
    }
    7.14286% {
      transform: translate(0, 0);
    }
    8.92857% {
      transform: translate(5px, 0);
    }
    10.71429% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
}

.success-animation {
  max-width: 420px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .checklist {
    @include imgIcon(130px, 130px, "../img/checklist.svg");
    animation: shake-animation 4.72s ease;
    animation-iteration-count: infinite;
  }
  &.pledge {
    position: relative;
    &::before {
      content: "";
      background-image: url(../img/animation.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 100%;
      height: 100%;
      position: absolute;
      // top: rem(-150px);
      // z-index: -3;
      left: 0;
      animation: pledge 2s;
      animation-iteration-count: infinite;
    }
  }
}

// copyright
.copyright {
  background: linear-gradient(180deg, #ffd300 0%, #fa8b51 100%);
  // height: 54; // on big screen
  height: 27px; // on mobile
  span {
    @include font(400, 12px, $color-blackcl);
  }
  flex-shrink: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.copyright-noposition {
  background: linear-gradient(180deg, #ffd300 0%, #fa8b51 100%);
  // height: 54; // on big screen
  padding: 5px 0px;
  span {
    @include font(400, 12px, $color-blackcl);
  }
  flex-shrink: 0;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 16px;
}
@include maxW(575px) {
  .copyright {
    span {
      @include font(400, 11px, $color-black);
    }
  }
  .copyright-noposition {
    span {
      @include font(400, 11px, $color-black);
    }
  }
}
@include maxW(301px) {
  .copyright {
    span {
      @include font(600, 10px, $color-black);
    }
  }
  .copyright-noposition {
    span {
      @include font(600, 10px, $color-black);
    }
  }
}

// footer
@include minW(576px) {
  .footer {
    margin-top: auto;
    background-color: $bgr-footer;
    position: relative;
    padding: 20px 20px 17px 24px !important;

    &-img {
      @include midFlex(flex-start, center);
      .utop-logo {
        width: 100%;
        max-width: 70px;
        height: 38px;
        display: block;
      }
      .fpt-logo {
        margin-left: 20px;
        width: 100%;
        max-width: 63px;
        height: 38px;
        display: block;
      }
    }
    &-position {
      ._section-title {
        margin-top: 22px;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 0 !important;
      }

      .download-app {
        width: 100%;
        max-width: 210px;
        background-color: $color-white;
        display: flex;
        align-items: center;
        margin-top: 10px;
        &__qr-code {
          margin-right: 12px;
        }
        &__text-and-image {
          &--text-scan-qr {
            @include font(400, 12px, $color-black);
            line-height: 16px;
            text-align: left;

            margin-bottom: 0px;
          }
          &__ios-android {
            @include midFlex(space-between, center);
            flex-wrap: wrap;
            &--ios-img {
              display: flex;
              align-items: center;
            }
            &--android-img {
              display: flex;
              align-items: center;
            }
            .--ios-text {
              @include font(normal, 12px, $color-black);
              margin-left: 6px;
            }
          }
        }
      }

      .text-block {
        margin-bottom: 6px;
        p {
          margin-bottom: 0;
          @include font(400, 14px, #000000);
          width: 100%;
          max-width: 218px;
        }
        @include font(400, 14px, #000000);
      }

      .icon {
        flex-shrink: 0;
      }
      .icon-dots {
        width: 15px !important;
        height: 25px !important;
        flex-shrink: 0;
      }

      .square-icon-list {
        & > :not(:last-child) {
          margin-right: 1.25vw;
        }

        & > a {
          flex-shrink: 0;
        }
        .icon-footer {
          width: 31px;
          height: 31px;
          border-radius: 5px;
        }
      }

      .button-download {
        width: 12px;
        height: 15px;
      }

      .--modify-layout {
        @include midFlex(space-between, center);

        .bo-cong-thuong {
          width: 100%;
          @include midFlex(flex-end, center);
          margin-bottom: 16px;
          &__icon {
            width: 100%;
            max-width: 86px;
          }
        }

        @include tablet_max {
          .bocongthuong {
            display: none;
          }
        }
      }

      .bocongthuong--desktop {
        margin-top: 0.5rem;
        img {
          max-width: 126px;
        }
      }

      // ***
      .logo-bo-cong-thuong {
        width: 100%;
        max-width: 96px;
      }
    }
  }
  .mobile-footer {
    display: none;
  }
}

.mobile-footer {
  overflow-x: hidden;
}

@include maxW(575px) {
  .footer {
    display: none;
  }
  .mobile-footer {
    margin-top: auto;
    padding: 14px 14px 21px 14px;
    background-color: $bgr-footer;
    position: relative;
    &-position {
      ._section-title {
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        text-transform: uppercase;
        color: #151515;
        margin-bottom: 9px;
      }

      .download-app {
        width: 100%;
        max-width: 243px;
        height: 95px;
        background-color: $color-white;
        @include midFlex(center, center);
        padding: 10px 12px 10px;
        margin-top: 19px;
        // &__qr-code {
        //   width: 100%;
        //   max-width: 75px;
        //   height: 75px;
        //   margin-right: 10px;
        // }
        &__text-and-image {
          &--text-scan-qr {
            @include font(500, 13px, $color-black);
            line-height: 16px;
            text-align: left;
          }
          &__ios-android {
            @include midFlex(space-between, center);

            &--ios-img {
              display: flex;
              align-items: center;
            }
            &--android-img {
              display: flex;
              align-items: center;
            }
            .--ios-text {
              @include font(normal, 12px, $color-black);
              margin-left: 6px;
            }
          }
        }
      }

      .text-block {
        margin: 0.25em;
        p {
          margin-bottom: 0;
          @include font(500, 12px, $color-blackcl);
        }
        @include font(500, 12px, $color-blackcl);
      }

      .icon {
        width: 25px !important;
        height: 25px !important;
        flex-shrink: 0;
      }
      .icon-dots {
        width: 15px !important;
        height: 25px !important;
        flex-shrink: 0;
        margin-right: -6px;
      }
      .square-icon-list {
        display: flex;
        & > :not(:last-child) {
          margin-right: 17px;
        }
        // & > * {
        //   flex-shrink: 0;
        // }
        .icon-footer-mobile {
          width: 25px;
          height: 25px;
        }
      }

      .button-download {
        width: 12px;
        height: 15px;
      }
      .--modify-layout {
        @include midFlex(space-between, center);
        margin-bottom: -17px;
        .bo-cong-thuong {
          width: 100%;
          @include midFlex(flex-end, center);
          margin-bottom: 16px;
          &__icon {
            width: 100%;
            max-width: 86px;
          }
        }

        @include tablet_max {
          .bocongthuong {
            display: none;
          }
        }
      }

      .bocongthuong--desktop {
        margin-top: 0.5rem;
        img {
          max-width: 126px;
        }
      }

      // ***
      .logo-bo-cong-thuong {
        width: 100%;
        max-width: 113px;
        margin-right: 16px;
      }
    }
  }
}

/*
 * button that is placed at the bottom of screen, used to apply/continue/confirm
*/
.button-confirm_at-bottom-screen {
  border-radius: 20.5px;
  max-width: 800px;
  @include font(700, 14px, #ffffff);
}

.bgr-gradient-orange {
  background: linear-gradient(243.97deg, #ffd300 27.74%, #fa8b51 143.54%);
}

// reset appearance of coreui button
._outline-none {
  outline: none !important;
}

._border-none {
  border: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-x-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

// custom input checkbox & radio button
.form-check-input[type="checkbox"] {
  border-radius: 50%;
}

.form-check-input {
  border-color: var(--cui-form-check-input-checked-border-color, #ffb600);
  border-width: 2px;
  width: 16px;
  height: 16px;
}

.form-check-input:checked {
  background-color: var(--cui-form-check-input-checked-bg-color, #ffb600);
  border-color: var(--cui-form-check-input-checked-border-color, #ffb600);
}

.form-check-input:checked[type="radio"] {
  background-image: var(
    --cui-form-check-radio-checked-bg-image,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='rgba%28255, 182, 0, .87%29'/%3e%3c/svg%3e")
  );
  background-color: white;
}

.bgr--disabled {
  background: $sub-color;
}

.text--disabled {
  color: $sub-color;
}

.button__effect {
  cursor: pointer;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    &:hover,
    &:focus,
    &:active {
      opacity: 0.9;
    }
  }
}

._text-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
// custom modal of coreUI
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: initial !important;
  outline: 0;
}

// uncomment the code below to make the dialog placed center of screen by vertical
// .modal.show .modal-dialog {
//   position: absolute;
//   bottom: 55vh;
//   transform: translateY(50%);
// }

._break-long-word {
  word-wrap: break-word;
  word-break: break-all;
}

// * custom dropdown
.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;
  color: #fff;
  background-color: #ebedef;
}

@include maxW(575px) {
  .download-app-container {
    width: 100%;
    max-width: 111px;

    border-radius: 7px;
    background: linear-gradient(220.97deg, #ffd300 -5.25%, #fa8b51 147.32%);
    color: #ffffff;
    padding: 7px;
    align-items: center;
    display: flex;
    justify-content: center;
    .separate-line {
      margin-left: 6px;
      margin-right: 6px;
      line-height: 10px;
      width: 1px;
      height: 10px;
      border-left: 0.5px solid #fff;
    }
    .separate-icon {
      margin-right: 6px;
    }
    .icon-download {
      display: flex;
      align-items: center;
    }
    a {
      text-decoration: none;
      color: #ffffff;
      margin-left: 10px;
    }
    .immediate-down {
      @include font(bold, 12px, $color-white);
      margin-bottom: 0px;
      line-height: 16px;
    }
  }
}

// ***
.c-app {
  max-width: 800px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// *** start - react-spring-bottom-sheet custom
[data-rsbs-overlay] {
  max-height: 85vh;
  max-width: 800px !important;
  margin: 0 auto !important;

  // @media screen and (min-width: 800px) {
  //   max-height: 60vh;
  // }
}

[data-rsbs-root]:after {
  max-width: 800px !important;
  margin: 0 auto !important;
}
// *** end - react-spring-bottom-sheet custom

// *** start - react-modal customizations
.common-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2;
  max-width: 800px;
  margin: 0 auto;

  overflow: overlay;
  display: flex;
  padding: 16px;
  padding-top: calc(56px + 16px);
}

.common-modal {
  margin: auto;
  outline: none;
}

// custom transition
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.checkQA {
  background-color: red;
  color: #fff;
  text-align: center;
  font-weight: 600;
}
// *** end - react-modal customizations
