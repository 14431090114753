@import "scss/variables";

// ---------------- General Style -----------
@mixin midFlex($justCon, $align) {
  display: flex;
  justify-content: $justCon;
  align-items: $align;
}

@mixin font($weight, $size, $color) {
  // font-family: $font-body;
  font-weight: $weight;
  font-size: $size;
  color: $color;
}
@mixin imgIcon($width, $height, $url) {
  width: $width;
  height: $height;
  background-image: url($url);
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
}
@mixin placeholder($color, $size) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    font-size: $size;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }

  &:-ms-input-placeholder {
    color: $color;
    font-size: $size;
  }

  // Internet Explorer 10+
  &::-webkit-input-placeholder {
    color: $color;
    font-size: $size;
  }

  // Safari and Chrome
}
// ------------ media query responsive mixins -----------
@mixin desktop-large {
  @media (max-width: $lg-max) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: $md-max) {
    @content;
  }
}
@mixin desktop_min {
  @media (min-width: $md-min) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: $sm-max) and (min-height: 500px) {
    @content;
  }
}
@mixin tablet_min {
  @media (max-width: $md-min) {
    @content;
  }
}
@mixin tablet_max {
  @media (min-width: $xxs-max) {
    @content;
  }
}

@mixin mobile {
  @media screen and (min-width: 320px) and (max-width: $xs-max) {
    @content;
  }
}

@mixin maxW($args) {
  @media (max-width: $args) {
    @content;
  }
}

@mixin minW($args) {
  @media (min-width: $args) {
    @content;
  }
}

@mixin minH($args) {
  @media (min-height: $args) {
    @content;
  }
}

@mixin maxH($args) {
  @media (max-height: $args) {
    @content;
  }
}

@mixin desktop_lc {
  @media screen and (min-width: $sm-min) and (max-width: $md-max) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet_lc {
  @media screen and (min-width: $xs-min) and (max-width: $sm-max) and (orientation: landscape) {
    @content;
  }
}

@mixin mobile_lc {
  @media screen and (max-width: $xs-max) and (orientation: landscape) {
    @content;
  }
}

//====== RESET CSS PAGE ===========//
img {
  vertical-align: middle;
  border-style: none; // Remove the border on images inside links in IE 10-.
  max-width: 100%;
}
table {
  @include font(400, 14px, #000000 !important);
  thead {
    th {
      background-color: rgb(237, 237, 237);
      border-bottom: 0px solid !important;
      border-top: 0px solid !important;
      @include font(500, 14px, #000000);
      &:first-child {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
      }
      &:last-child {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }
  }
  tbody {
    tr {
      &:first-child {
        td {
          border-top: 0px solid !important;
        }
      }
    }
    td {
      border-top: 1px dashed rgb(222, 222, 222) !important;
    }
  }
}
// a.btn{
//     line-height: 1.8;
// }
.btn {
  font-size: 14px;
  font-weight: 600;
  &.btn-pill {
    border-radius: 50em !important;
  }
  &.btn-md {
    min-height: 40px;
  }
  &.btn-lg {
    font-size: 18px;
    font-weight: 600;
  }
}
.form-group {
  label {
    @include font(500, 16px, rgb(21, 21, 21));
    .require {
      @include font(500, 16px, rgb(232, 80, 91));
    }
  }
  .custom-select {
    background: #fff url("../img/arrow-down.png") no-repeat right 0.75rem
      center/12px 10px;
  }
  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background: #fff url("../img/calendar.svg") no-repeat left 0.05rem
      center/20px 20px;
  }

  .form-control,
  .custom-select {
    height: calc(1.5em + 0.75rem + 9px);
    @include font(500, 18px, rgb(21, 21, 21));
    @include placeholder(rgb(211, 211, 211), 18px);
    border: 1px solid rgb(211, 211, 211);
    border-radius: 7px;
  }
  .form-control:disabled,
  .form-control[readonly] {
    color: rgb(179, 179, 179);
  }
}
.pagination {
  .page-item {
    &.active {
      .page-link {
        background-color: #ffb600;
        border-color: #ffb600;
        @include font(400, 14px, #ffffff);
      }
      &:hover {
        .page-link {
          background-color: #ffb600;
          border-color: #ffb600;
          @include font(400, 14px, #ffffff);
        }
      }
    }
    &:hover {
      .page-link {
        background-color: #d0d0d0;
        border-color: #d0d0d0;
        @include font(400, 14px, rgb(21, 21, 21));
      }
    }
    .page-link {
      @include font(400, 14px, rgb(21, 21, 21));
      background-color: rgb(237, 237, 237);
      border-color: rgb(237, 237, 237);
      padding: 0.4rem 0.7rem;
      margin-left: 8px;
      border-radius: 0.25rem;
      &:focus {
        box-shadow: none;
      }
    }
  }

  // .page-item.active .page-link {
  //     z-index: 3;
  //     color: #fff;
  //     background-color: #FFB600;
  //     border-color: #FFB600;
  //   }
}
.custom-radio {
  padding-left: 0 !important;
  margin-bottom: 16px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  label {
    padding: 10.5px 20px;
    padding-left: 50px;
    display: flex;
    font-size: 16px;
    border: 1px solid rgb(227, 227, 227);
    border-radius: 7px;
    font-weight: 400;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 16px;
    bottom: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    border: 2px solid rgb(227, 227, 227);
    border-radius: 50%;
    &:after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #ffb600;
      content: "";
      position: absolute;
      display: none;
    }
  }
  &:hover input ~ .checkmark {
    background-color: #ffffff;
    border: 2px solid #ffb600;
  }
  input:checked {
    & ~ .checkmark {
      background-color: #ffffff;
      border: 2px solid #ffb600;
      &:after {
        display: block;
      }
    }
    & ~ label {
      border: 1px solid #ffb600;
    }
  }
}
.border-left {
  border-left: 1px dashed rgb(222, 222, 222) !important;
  &.has-text {
    position: relative;
    &::before {
      content: attr(data-text);
      width: 70px;
      height: 70px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      margin-left: -35px;
      background-color: #fff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}
