@import "scss/mixins";
@import "scss/variables";



.market-images {
    padding-top: 60px;
    align-items: center;
    background-color: rgba(239, 239, 239, 1);

    .store-header__wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
    }

    // .scroll-top {
    //     position: absolute;
    //     top: 0;
    // }

    .swiper-story {
        padding-left: 10px;
    }

    .title-album {
        margin-left: 8px;
        @include font(600, 16px, #000);
    }

    .album {
        margin-top: 16px;
        padding-top: 16px;
        background-color: #fff;

        .icon-pause {
            width: 2.5vw;
            height: 2.5vw;
        }
    }

    .txt-duration-story {
        position: absolute;
        bottom: 10px;
        left: 10px;
    }

    .txt-duration-album {
        position: absolute;
        bottom: 6px;
        left: 6px;
    }

    .item-video-story {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.35);
        width: 100%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
    }

    .item-video-album {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.35);
        width: 100%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .list-story {
        padding-bottom: 20px;

        .image-story {
            width: 100%;
            aspect-ratio: 0.8;
            object-fit: cover;
            border-radius: 7px;
            position: relative;
        }
    }

    .list-image {
        display: flex;
        flex-wrap: wrap;

        .view-image {
            margin-top: 2px;
            padding-left: 1px;
            padding-right: 1px;
            width: 25%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
        }

        .see-more {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            width: 100%;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .image-category {
        width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
    }


    .image-viewer-container {
        z-index: 11;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgba(0, 0, 0, 1);
        padding: 10px;

        .image-viewer-header {
            display: flex;
            margin-top: 1vh;
            width: 100%;
            max-width: 800px;

            .current-title {
                display: flex;
                flex: 1;
                @include font(600, 18px, #fff);
            }
        }

        .image-viewer-content {
            margin-top: 2vh;
            height: 78vh;
            width: 100%;
            display: flex;
        }

        .story-viewer-content {
            margin-top: 2vh;
            height: 85vh;
            width: 100%;
            display: flex;
        }

        .image-viewer-pagination {
            height: 10vh;
            width: 100%;
            margin-top: 2vh;
            max-width: 800px;

            .icon-pause {
                width: 13px;
                height: 13px;
            }

            .swiper-slide {
                width: auto !important;
            }

            .active-image {
                position: absolute;
                border: 3px #fff solid;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
            }

            .inactive-image {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.7);
                display: flex;
                justify-content: center;
                align-items: center;

            }

            .txt-duration {
                position: absolute;
                bottom: 5px;
                left: 5px;
                color: #fff;
            }
        }

        .item-image-pagination {
            object-fit: contain;
            height: 10vh;
            display: flex;
            // margin-left: auto;
            // margin-right: auto;
        }

        .item-image {
            object-fit: contain;
            height: 100%;
            display: flex;
            margin-left: auto;
            margin-right: auto;
        }

        .close-button {
            top: 15px;
            right: 15px;
            padding: 15px;
            margin: -15px;
        }
    }

    .item-story {
        max-width: 800px;
        height: 500px;
        width: 300px;
    }

    .title-filter {
        @include font(600, 16px, #FFB600);
        margin-top: 15px;
        text-align: center
    }

    .filter-category-name {
        @include font(400, 15px, rgba(21, 21, 21, 0.7));
        border-bottom: 0.5px solid #DEDEDE;
        padding-bottom: 15px;
        margin-top: 15px;
        display: flex;

        a {
            color: rgba(21, 21, 21, 0.7);
        }

        a:hover {
            text-decoration: none;
        }

    }

    .filter-category-name-active {
        @include font(600, 15px, rgba(21, 21, 21, 1));
        border-bottom: 0.5px solid #DEDEDE;
        padding-bottom: 15px;
        margin-top: 15px;
        display: flex;

        a {
            color: rgba(21, 21, 21, 1);


        }

        a:hover {
            text-decoration: none;
        }

    }

    .view-filter {
        padding: 12px 20px 30px 20px;


        .txt-title-category {
            flex: 1;
        }

        .icon-check {
            width: 15px;
            height: 11px;
        }
    }
}