.receiver-info__wrapper {
  margin-bottom: 8px;

  .receiver-info__heading {
    font-weight: 600;
    font-size: 16px;
    color: #000;

    margin-bottom: 8px;
  }

  .receiver-info__input-box {
    background: #fff;
    padding: 15px;
    margin-bottom: 8px;

    border-radius: 7px;

    display: flex;
    align-items: center;

    .icon-left {
      margin-right: 1rem;
    }

    .icon-delete {
      width: 30px;
      height: 30px;
    }
  }

  .hint {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.233333px;

    color: #f03939;
  }
}
