.dock-container {
  .dock-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: 10;

    background: #000;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility 0.3s, opacity 0.3s linear;
    -o-transition: visibility 0.3s, opacity 0.3s linear;
    transition: visibility 0.3s, opacity 0.3s linear;

    &--show {
      opacity: 0.5;
      visibility: visible;
    }
  }

  .dock {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background-color: #fff;

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    -webkit-transform: translateY(100%);

    -ms-transform: translateY(100%);

    transform: translateY(100%);
    -webkit-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;

    z-index: 11;

    &--show {
      -webkit-transform: translateY(0%);
      -ms-transform: translateY(0%);
      transform: translateY(0%);
    }

    @media screen and (min-width: 800px) {
      max-width: 800px;
      margin: 0 auto;
    }
  }

  // .dock__top {
  //   padding: 8px 0px;

  //   &__drag-indicator {
  //     width: 32px;
  //     height: 3px;
  //     background: #e4e4e4;
  //     border-radius: 2px;
  //     margin-left: auto;
  //     margin-right: auto;
  //   }
  // }

  .dock__children {
    position: relative;

    &--empty {
      max-height: 30vh;
      padding: 1em;
    }
  }

  .dock__children__skeleton-container--default {
    padding: 1em;
  }

  .dock__btn-close {
    border: 0;
    outline: none;
    padding: 8px;
    margin: 0;
    background-color: transparent;
    line-height: 16px;

    position: absolute;
    top: 8px;
    right: 8px;

    border-radius: 50%;
  }
}
