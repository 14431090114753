@import "scss/mixins";
@import "scss/variables";

.list-store-view-page {
  min-height: 100vh;
  position: relative;

  .store-header__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .content-list-store {
    margin-top: 60px;
  }

  .button-scroll-top {
    position: fixed;
    bottom: 90px;
    right: 10px;
    z-index: 1;
    cursor: pointer;

    .icon-scroll-top {
      width: 50px;
      height: 50px;
      background: linear-gradient(#fa8b51 0%, #ffd300 100%);
      border-radius: 50%;
      padding: 10px;
    }
  }
}
