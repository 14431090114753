@import "scss/mixins";
@import "scss/variables";

.content-store-item {
  background: white;
  align-items: center;
  @include midFlex(flex-start, center);
  width: 100%;
  padding: 10px;
  margin: 0px 0px 10px 0px;
  border-radius: 8px;
  .content-avatar {
    @include midFlex(center, center);
    border: 1px solid;
    border-color: #ffb600;
    padding: 1px;
    border-radius: 50%;
    margin: 0px 10px 0px 0px;
    .avatar-img {
      max-width: 100px;
      max-height: 100px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }
  .content-store-info {
    width: 85%;
    height: 100%;
    display: grid;
    justify-content: space-between;
    .content-store-info_title {
      font-weight: 600;
      color: black;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .content-store-info-contact {
      @include midFlex(flex-start, space-between);
      .content-store-info-contact_icon-right {
        margin: 0px 10px 0px 0px;
      }
    }
  }
}
