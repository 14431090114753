@import "scss/mixins";
@import "scss/variables";

.order-action-container {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 12px 16px;

  // todo: ?? unused
  // &.full-width {
  //   & > a,
  //   .rating {
  //     width: 100%;
  //   }
  // }

  & > a,
  .rating {
    width: 100%;

    & > button {
      width: 100%;
      border: none;
      outline: none;
      font-size: 14px;
      line-height: 19px;
      font-weight: bold;
      padding: 10px 16px;
      text-align: center;
      letter-spacing: 0.233333px;
    }

    & + a {
      margin-left: 12px;
    }
  }

  button.reset {
    color: #fff;
    border-radius: 20.5px;
    background: linear-gradient(220.97deg, #ffd300 -5.25%, #fa8b51 147.32%);
  }

  button.btn-re-order {
    color: #ffb600;
    border-radius: 20.5px;
    background-color: #fff;
    border: 1px solid #ffb600;

    &:disabled {
      background: #d2d2d2;
      color: #ffffff;
      border: 0;
    }
  }
}

.rate-store__wrapper-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .rate-store__title {
    @include font(600, 16px, black);
  }

  .rate-store__content {
    @include font(400, 12px, #8f8f8f);
    margin-bottom: 16px;
  }
}
