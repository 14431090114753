.button-base {
  // reset
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  line-height: 0;

  // text
  text-align: inherit;

  // button effects
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }

  &:active {
    opacity: 0.7;
  }

  &:disabled {
    &:hover,
    &:focus,
    &:active {
      opacity: 0.9;
    }
  }
}
