@import "scss/variables";

.coupon-detail-page {
  height: 100%;
  min-height: 100vh;
  position: relative;
  padding-bottom: 2rem;

  .banner {
    width: 100%;
    max-height: 450px;
  }

  .brief-info {
    background-color: $color-white;
    padding: 16px;

    .brief-info__coupon-name {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.3px;
      color: $color-black;
    }

    .brief-info__expiration-date {
      margin-top: 10px;

      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $color-spanish_gray;
    }
  }

  .manual {
    margin-top: 16px;

    .manual__title {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.0625003px;
      color: $color-chinese_black;

      margin-left: 16px;
    }

    .manual__content {
      margin-top: 8px;
      margin-left: 8px;
      margin-right: 8px;
      padding: 19px 14px;

      background-color: $color-white;
      border-radius: 7px;

      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: $color-chinese_black;

      // reset content
      p {
        margin: 0px;
        padding: 0px;
      }
    }
  }

  .btn--main__container {
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 0px 16px;

    .btn--main {
      width: 100%;
      padding: 11px 0px 10px;

      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.233333px;
      color: $color-white;

      background: $color-gradient_orange-yellow;
      border-radius: 20.5px;
      box-shadow: none !important;
    }
  }
}
