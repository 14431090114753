.__divider--ticket {
  margin: 0px 8px;
  height: 16px; // should be an even number
  background: #fff;
  position: relative;

  .__divider--ticket__dashed-line {
    border-top: 2px dashed #d2d2d2;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    z-index: 1; // should over z-index of :after (default: 0)
  }
}

.__divider--ticket::before,
.__divider--ticket::after {
  content: "";
  background: transparent;
  box-sizing: content-box; // important spec
  border-radius: 50%;

  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;

  border: 8px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
}

.__divider--ticket::before {
  left: -8px;
  transform: translate(-50%, -50%) rotate(45deg);
}

.__divider--ticket::after {
  right: -8px;
  transform: translate(50%, -50%) rotate(225deg);
}
