.edit-menu {
  max-height: 85vh;

  hr {
    //todo: apply all at _custom.scss
    border: 1px solid #f1f1f1;
  }

  .options-list {
    & > :last-child {
      hr {
        display: none;
      }
    }
  }

  .product-image__container {
    line-height: 0;
  }

  .product-image {
    height: 240px;
    width: auto;
    max-width: 100%;
    object-fit: fill;
    object-position: center;
    border-radius: 9px !important;
    display: block;
    margin: 0 auto;
  }

  .menu-bottom {
    z-index: 2;
    box-shadow: 0px -2px 12px 2px rgba(0, 0, 0, 0.2);
  }
}
