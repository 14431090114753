@import "scss/variables";

.cart-bottom-bar__spacer {
  height: 73px; // offset height of this bar
}

.cart-bottom-bar__container {
  box-shadow: 0px 25px 30px 9px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: $color-white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  .cart-bottom-bar__left {
    display: flex;
    align-items: center;

    .cart-bottom-bar__icon-button {
      padding: 0;
      position: relative;

      border: 0;
      outline: 0;
      background: transparent;
    }

    .cart-bottom-bar__quantity {
      background: $color-danger;
      color: $color-white;
      border-radius: 50em;
      border: 0.5px solid $color-white;

      font-size: 9px;
      font-weight: 700;

      position: absolute;
      top: 15%;
      left: 100%;
      transform: translate(-50%, -50%);

      display: none;

      // badge
      min-width: 12px;
      min-height: 12px;
      padding: 1px 3px;
      line-height: 12px;
      letter-spacing: 0.25px;

      text-align: center;
      vertical-align: middle;
      white-space: nowrap;
    }

    .cart-bottom-bar__price {
      font-weight: 600;
      font-size: 15px;
      margin-left: 12px;
      color: $color-orange;
    }

    .cart-bottom-bar__price--disabled {
      color: $sub-color;
    }
  }

  .cart-bottom-bar__button {
    border: none;
    outline: none;
    max-width: 800px;
    margin-left: 0.5rem;
    padding-top: 10px;
    padding-bottom: 10px;

    flex-grow: 1;
    max-width: 190px;

    border-radius: 50em;

    background: $color-linear;

    color: $color-white;
    font-weight: 700;
    font-size: 14px;

    &:disabled {
      background: $sub-color;
    }
  }
}
