@import "scss/variables";

.detail-rating-post__container--show {
  display: block;
  visibility: visible;
  opacity: 1;
}
.detail-rating-post__container--hide {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.detail-rating-post__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;

  background: $color-white;

  transition: visibility 0.7s, opacity 0.7s, width 0s linear;
  transition-delay: 0s;

  // for temporarily responsive on PC mode
  max-width: 800px;
  margin: 0 auto;

  .detail-rating-post__content-container {
    overflow: scroll;
    width: 100%;
    height: 100%;
    padding-bottom: 70px; // reply fixed section

    .detail-rating-post__post-wrapper {
      padding: 16px 8px;
      padding-bottom: 70px; // reply fixed section

      background-color: $color-white;
    }
  }

  .reply-input-box--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 18px 8px;
    border-top: 0.5px solid $color-sub-gray;
    background-color: $color-white;

    // for temporarily responsive on PC mode
    max-width: 800px;
    margin: 0 auto;
  }
}
