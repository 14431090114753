@import "scss/variables";
@import "scss/mixins";
$color-border-gray: #e3e3e3;

.store-section {
  height: 100%;

  // padding: 12px;
  margin: 10px;
  border-radius: 8px;
}

.loading-wrapper {
  height: 100%;
  @include midFlex(center, center);
  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #f9b115;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.search-page {
  margin-bottom: 80px;
}
