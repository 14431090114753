.reply-box__container {
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  .reply-box__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;

    flex-shrink: 0;
    margin-right: 8px;
  }

  .reply-box__input-container {
    padding: 10px 16px 10px;
    border-radius: 20px;
    border: 1px solid rgb(227, 227, 227);
    flex-grow: 1;

    .span-content-editable {
      text-align: left;

      /* demo */
      width: 100%;

      max-height: 64px; // 4 * line-height(16px)
      outline: none;

      display: block;
      overflow: hidden;
      resize: none;

      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    // .reply-box__input-container__input {
    //   font-size: 12px;
    //   font-weight: 400;
    //   line-height: 16px;

    //   border: none;
    //   outline: none;

    //   width: 100%;
    // }
  }

  .span-content-editable[contenteditable]:empty::before {
    // content: "Placeholder still possible";
    content: attr(placeholder);
    color: gray;
  }

  .button-send {
    flex-shrink: 0;

    box-shadow: none;

    transition: visibility 0.7s, opacity 0.7s, width 0s linear;
    transition-delay: 0s;
  }

  .button-send--show {
    width: 32px;
    margin-left: 12px;
    visibility: visible;
    opacity: 1;
  }

  .button-send--hide {
    width: 0px;
    visibility: hidden;
    opacity: 0;
  }
}
