@import "scss/variables";
@import "scss/mixins";

.food-list__container {
  .product-list {
    & > * {
      padding-top: 12px;
      padding-bottom: 12px;
      border-top: 0.8px solid #dedede;
    }
    & > :first-child {
      border: none;
      padding-top: 0px;
    }
  }
}

.food-item {
  .icon-garbage_can {
    @include imgIcon(12px, 12px, "../img/garbage-can.svg");
    margin-right: 5px;
  }

  .button_small {
    flex-shrink: 0;
    padding: 0.5rem;
    height: 22px;
    border: 1px solid #e3e3e3;
    border-radius: 12px;

    color: #121212;
    font-size: 12px;
    font-weight: 500;

    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
  }
}
