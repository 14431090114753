.checkbox-button {
  .rect {
    width: 18px;
    height: 18px;
    border: 2px solid #ffb600;
    box-sizing: border-box;
    border-radius: 4px;

    line-height: 0px;
    flex-shrink: 0;
  }
  .tick {
    display: none;
    transform: translate(65%, 10%) rotate(45deg);
    height: 10px;
    width: 6px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
  }
}

.checkbox-button--checked {
  .rect {
    background: #ffb600;
  }
  .tick {
    display: inline-block;
  }
}
