@import "scss/mixins";
@import "scss/variables";

.store-view__rating__header {
  width: 100%;
  .add-margin-top {
    margin-top: 41px;
  }
  .form-check-input {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
  h1 {
    @include font(600, 16px, $color-black);
    line-height: 21.86px;
  }
  &__text-header {
    display: flex;
    justify-content: space-between;
    &__left {
      display: flex;
      h1 {
        @include font(600, 16px, $color-black);
        line-height: 21.86px;
        margin-bottom: 0px;
      }
      span {
        @include font(500, 12px, #b4b4b4);
        line-height: 13px;
        margin-left: 4px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        @include font(600, 12px, #ffb600);
        margin-right: 4px;
      }
    }
  }
  &__icon-star {
    line-height: 16px;

    .start-list {
      & > :not(:first-child) {
        margin-left: 3px;
      }
    }
  }
  &__star-filter {
    @include midFlex(flex-start, center);
    &--button {
      margin-right: 4px;
      button {
        width: 100%;
        max-width: 40px;
        height: 26px;
        background: #f8f8f8;
        @include font(300, 13px, $color-black);
        @include midFlex(center, center);
        margin-right: 4px;
        border-radius: 5px;
        padding: 5px 8px;
        border: none;
        &.active {
          border: 1px solid $color-ucla_gold;
          background-color: $color-light-yellow;
          padding: 4px 7px;
        }
      }
      .--star-filter {
        img {
          margin-bottom: 1px;
          margin-left: 4px;
          width: 10px;
          height: 10px;
        }
      }
      .--star-empty {
        img {
          margin-bottom: 1px;
          margin-left: 4px;
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  &__choosing-cmt {
    @include midFlex(flex-start, center);
    margin-top: 14px;
    img {
      width: 17px;
      height: auto;
    }
    &--text {
      @include font(500, 12px, $color-black);
      margin-bottom: -2px;
      line-height: 13px;
      margin-left: 7px;
    }
  }
}
