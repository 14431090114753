@import "scss/variables.scss";

$clr-store-name: $color-black;
$clr-distance: $color-old_silver;
$clr-open: $color-green--middle;
$clr-closed: $color-red--pigment;
$clr-opening-info: $color-spanish_gray;
$clr-divider-horiz: $color-s-gray;
$clr-merchant-name: $color-black;
$clr-promotion-tag__bg: $color-white--floral;
$clr-promotion-tag__text: $color-gray--gunmetal;
$clr-short-description: $color-chinese_black;
$clr-rating-text: $color-chinese_black;
$clr-empty-screen__text: $color-warm-grey;
$clr-empty-screen__bg: $color-gray--bright;

.list-store-of-merchant {
  min-height: 50vh;
}

.list-store-of-merchant__merchant-info {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid $clr-divider-horiz;

  .logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 14px;
    flex-shrink: 0;
    align-self: flex-start;
  }

  .merchant-name {
    font-weight: 600;
    font-size: 16px;
    color: $clr-merchant-name;
    line-height: 18px;

    padding-right: 24px; // 32 - 8
  }

  // .tags {
  //   display: flex;
  //   align-items: center;
  //   margin-top: 8px;
  //   overflow: hidden;
  //   flex-wrap: wrap;
  //   max-height: 16px;

  //   .tag {
  //     background: $color-corn-silk;
  //     border-radius: 2px;
  //     padding: 0px 8px;

  //     font-weight: normal;
  //     font-size: 12px;
  //     line-height: 16px;
  //     text-align: center;
  //     letter-spacing: 0.15px;
  //     color: $color-black;

  //     white-space: nowrap;
  //   }

  //   & > :not(:first-child) {
  //     margin-left: 4px;
  //   }
  // }

  .promotion-tags {
    margin-top: 2px;
    padding-right: 4px;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    // * rule: max 2 lines
    // 18px: line height of each item ~ each line
    // 6px: margin top of each line
    max-height: calc(2 * 18px + 2 * 6px);

    overflow: hidden;

    .promotion-tag {
      background-color: $clr-promotion-tag__bg;
      margin-top: 6px;
      padding: 1px 8px;
      padding-right: 2px; // = 8px (original design) - 3px (border)  - 3px (border-image-outset)

      // if use the line below (border-left), container must have 1px of padding (due to border-image-outset)
      border-right-width: 3px; // 3px because each hole is 2x2(px), yellow stroke is 0.5px -> 3 to make sure image not be cut
      border-right-style: solid;
      border-image-source: url("../../../../assets/svg/border-tag-promotion.svg");
      border-image-slice: 3; // keep adjust until reach the  best look
      border-image-width: 3px; // keep adjust until reach the  best look
      border-image-outset: 1;
      border-image-repeat: round;
      border-radius: 3px;

      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $clr-promotion-tag__text;

      white-space: nowrap;
    }

    & > :not(:last-child) {
      margin-right: 8px;
    }
  }

  .short-description {
    display: flex;
    align-items: flex-start;

    margin-top: 8px;
  }

  .short-description__icon {
    flex-shrink: 0;
    line-height: 12px;
  }

  .short-description__content {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.2px;
    color: $clr-short-description;
    line-height: 16px;
    margin-left: 8px;
  }
}

.list-store-of-merchant__infinite-scroll-wrapper {
  max-height: 70vh;
  overflow-y: overlay;

  .items-with-border {
    padding-bottom: 10px;

    & > * {
      border-bottom: 1px solid $clr-divider-horiz;
    }
  }
}

.list-store-of-merchant__store-row {
  padding: 8px 16px;
  padding-right: 24px;

  display: flex;
  align-items: center;
}

.list-store-of-merchant__store-row__left {
  margin-right: auto;

  .name {
    font-weight: 500;
    font-size: 14px;
    color: $clr-store-name;
    letter-spacing: 0.2px;
    line-height: 19px;
  }

  .rating-info {
    display: flex;
    line-height: 16px;
  }

  .rating-info__star-icons {
    line-height: 12px;
    margin-left: 6px;
    margin-right: 4px;
  }

  .rating-info__text {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.2px;
    color: $clr-rating-text;
  }

  .distance {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: $clr-distance;
    line-height: 16px;
  }

  .opening-info {
    display: flex;
    align-items: center;

    margin-top: 3px;

    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: $clr-opening-info;
    line-height: 16px;

    &--open {
      color: $clr-open;
    }
    &--closed {
      color: $clr-closed;
    }
  }

  .opening-time-info__divider {
    width: 1px;
    height: 12px;
    background-color: $color-grey_platinum;
    border-left: 0.5px solid $color-grey_platinum;

    margin: 0px 4px;
  }
}

.list-store-of-merchant__store-row__right {
  margin-left: 16px;
  display: flex;
  align-items: center;

  & > :not(:first-child) {
    margin-left: 8px;
  }
}

.list-store-of-merchant--empty-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 7px;

  height: 50vh;
  background-color: $clr-empty-screen__bg;
  text-align: center;

  &__text {
    margin-top: 24px;
    padding: 0px 36px;
    font-size: 16px;
    font-weight: 500;
    color: $clr-empty-screen__text;
  }
}
