@import "src/scss/variables";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000075;

  overflow: auto;
  display: flex;
  padding: 16px;
  padding-top: calc(56px + 16px);

  z-index: 1000;

  background: #00000040;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.3s, opacity 0.3s ease-in-out;
}

.overlayAfterOpen {
  opacity: 1;
  visibility: visible;
}

.overlayBeforeClose {
  opacity: 0;
  visibility: hidden;
}

.sheet {
  max-height: 85vh;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  outline: none;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  background-color: var(--clr-white, #fff);
  transform: translateY(100%);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;

  touch-action: pan-y;

  @media screen and (min-width: 800px) {
    max-width: 800px;
    margin: 0 auto;
  }
}

.sheetAfterOpen {
  transform: translateY(0%);
}

.sheetBeforeClose {
  transform: translateY(100%);
}

// .header {
// }

// .footer {
// }

.scrollContainer {
  flex-grow: 1;
  overflow: auto;
}

.dragIndicatorContainer {
  padding: 12px 0;
}

.dragIndicator {
  margin: 0 auto;
  width: 32px;
  height: 3px;
  border-radius: 1.5px;

  background: #e4e4e4;
}
