.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);

  max-width: 800px;
  margin: 0 auto;

  overflow: overlay;
  display: flex;
  padding: 16px;
  padding-top: calc(56px + 16px);
}

.modal {
  margin: auto;
  background-color: #fff;

  &:focus {
    outline: none;
  }
}
