/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// ------
.button__change-quantity {
  background: linear-gradient(243.97deg, #ffd300 27.74%, #fa8b51 143.54%);
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background: linear-gradient(243.97deg, #c1c1c1 16.41%, #979797 83.59%);
  }
}
