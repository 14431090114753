.icon-button {
  width: 2rem;
  height: 2rem;
  line-height: 0;

  border-radius: 50%;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

  background-color: rgba(255, 255, 255, 1);

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  // button effect
  cursor: pointer;

  &:focus,
  &:active,
  &:visited {
    background-color: rgb(250, 250, 250);
    .icon-button__overlay {
      background-color: rgb(250, 250, 250);
      opacity: 0.3;
    }
  }

  .icon-button__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: inherit;
    z-index: 1;
  }

  .icon-button__overlay[disabled] {
    background-color: rgba(237, 237, 237, 0.7);
  }
}

.icon-button[disabled] {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  cursor: default;
}
