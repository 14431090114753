@import "scss/variables";

.categories__container {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);

  .horizontally-scrolled-items {
    display: flex;
    width: 100%;
    overflow-x: auto;
    position: relative;
    background: white;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 1px;
  }

  .item {
    font-weight: 500;
    font-size: 14px;
    color: #979797;

    max-width: 20ch; // maximum 24 character  + 3 dots
    min-width: 11ch;
    flex-grow: 1;
    flex-shrink: 0;

    display: flex;
    justify-content: center;

    span {
      line-height: 15px;
      padding-top: 10px;
      padding-bottom: 8px;
    }
  }

  .item--active {
    color: #151515;
    border-bottom: 2px solid $color-ucla_gold;
    font-weight: 600;
  }

  .item-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
