// margin 4px
._m-2px {
  margin: 2px !important;
}
._my-2px {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
._mx-2px {
  margin-left: 2px !important;
  margin-right: 2px !important;
}
._mt-2px {
  margin-top: 2px !important;
}
._mb-2px {
  margin-bottom: 2px !important;
}
._ml-2px {
  margin-left: 2px !important;
}
._mr-2px {
  margin-right: 2px !important;
}

// margin 4px
._m-4px {
  margin: 4px !important;
}
._my-4px {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
._mx-4px {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
._mt-4px {
  margin-top: 4px !important;
}
._mb-4px {
  margin-bottom: 4px !important;
}
._ml-4px {
  margin-left: 4px !important;
}
._mr-4px {
  margin-right: 4px !important;
}

// margin 8px
._m-8px {
  margin: 8px !important;
}
._my-8px {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
._mx-8px {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
._mt-8px {
  margin-top: 8px !important;
}
._mb-8px {
  margin-bottom: 8px !important;
}
._ml-8px {
  margin-left: 8px !important;
}
._mr-8px {
  margin-right: 8px !important;
}

// margin 10px
._m-10px {
  margin: 10px !important;
}
._my-10px {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
._mx-10px {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
._mt-10px {
  margin-top: 10px !important;
}
._mb-10px {
  margin-bottom: 10px !important;
}
._ml-10px {
  margin-left: 10px !important;
}
._mr-10px {
  margin-right: 10px !important;
}

// margin 12px
._m-12px {
  margin: 12px !important;
}
._my-12px {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
._mx-12px {
  margin-left: 12px !important;
  margin-right: 12px !important;
}
._mt-12px {
  margin-top: 12px !important;
}
._mb-12px {
  margin-bottom: 12px !important;
}
._ml-12px {
  margin-left: 12px !important;
}
._mr-12px {
  margin-right: 12px !important;
}

// margin 16px
._m-16px {
  margin: 16px !important;
}
._my-16px {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
._mx-16px {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
._mt-16px {
  margin-top: 16px !important;
}
._mb-16px {
  margin-bottom: 16px !important;
}
._ml-16px {
  margin-left: 16px !important;
}
._mr-16px {
  margin-right: 16px !important;
}

// margin 20px
._m-20px {
  margin: 20px !important;
}
._my-20px {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
._mx-20px {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
._mt-20px {
  margin-top: 20px !important;
}
._mb-20px {
  margin-bottom: 20px !important;
}
._ml-20px {
  margin-left: 20px !important;
}
._mr-20px {
  margin-right: 20px !important;
}

// padding 8px
._p-8px {
  padding: 8px !important;
}
._px-8px {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
._py-8px {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
._pt-8px {
  padding-top: 8px !important;
}
._pb-8px {
  padding-bottom: 8px !important;
}
._pl-8px {
  padding-left: 8px !important;
}
._pr-8px {
  padding-right: 8px !important;
}

// padding 10px
._p-10px {
  padding: 10px !important;
}
._px-10px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
._py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
._pt-10px {
  padding-top: 10px !important;
}
._pb-10px {
  padding-bottom: 10px !important;
}
._pl-10px {
  padding-left: 10px !important;
}
._pr-10px {
  padding-right: 10px !important;
}

// padding 12px
._p-12px {
  padding: 12px !important;
}
._px-12px {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
._py-12px {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
._pt-12px {
  padding-top: 12px !important;
}
._pb-12px {
  padding-bottom: 12px !important;
}
._pl-12px {
  padding-left: 12px !important;
}
._pr-12px {
  padding-right: 12px !important;
}

// padding 14px
._p-14px {
  padding: 14px !important;
}
._px-14px {
  padding-left: 14px !important;
  padding-right: 14px !important;
}
._py-14px {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
._pt-14px {
  padding-top: 14px !important;
}
._pb-14px {
  padding-bottom: 14px !important;
}
._pl-14px {
  padding-left: 14px !important;
}
._pr-14px {
  padding-right: 14px !important;
}

// padding 16px
._p-16px {
  padding: 16px !important;
}
._px-16px {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
._py-16px {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

._pt-16px {
  padding-top: 16px !important;
}
._pb-16px {
  padding-bottom: 16px !important;
}
._pl-16px {
  padding-left: 16px !important;
}
._pr-16px {
  padding-right: 16px !important;
}

// padding 20px
._p-20px {
  padding: 20px !important;
}
._px-20px {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
._py-20px {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
._pt-20px {
  padding-top: 20px !important;
}
._pb-20px {
  padding-bottom: 20px !important;
}
._pl-20px {
  padding-left: 20px !important;
}
._pr-20px {
  padding-right: 20px !important;
}

// border radius
._bd-radius-7px {
  border-radius: 7px;
}

// ! wrong place, missing code ?
._pl-6px {
  padding-left: 6px;
}

._ml-14px {
  margin-left: 14px;
}
._mr-16px {
  margin-right: 16px;
}
.__mb-16px {
  margin-bottom: -16px;
}
