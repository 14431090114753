.switch-button--common {
  padding: 0;
  margin: 0;
  // height: 20px;

  input[type="checkbox"].switch {
    position: absolute;
    display: none;
    transform: translateY(50%);
  }

  input[type="checkbox"].switch + div {
    vertical-align: middle;
    width: 40px;
    height: 20px;
    border-radius: 999px;
    background-color: rgba(220, 220, 220, 0.3);
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: background-color, box-shadow;
    transition-property: background-color, box-shadow;
    cursor: pointer;
    transform: translateY(5px);
  }

  input[type="checkbox"].switch + div {
    width: 40px;
    height: 16px;
  }

  input[type="checkbox"].switch:checked + div {
    background: rgba(250, 139, 81, 0.2);
  }

  input[type="checkbox"].switch + div > div {
    float: left;
    width: 20px;
    height: 20px;
    border-radius: inherit;
    background: #d3cdcd;
    -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: transform, background-color;
    transition-property: transform, background-color;
    pointer-events: none;
    transform: translateY(-2px);
  }

  input[type="checkbox"].switch:checked + div > div {
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
    background-color: #fa8b51;
  }

  input[type="checkbox"].switch:checked + div > div {
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, -2px, 0);
  }
}
