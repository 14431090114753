@import "scss/mixins";
@import "scss/variables";

.search-notfound-wrapper {
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 56px;
  background: #ffffff;

  .disabled-image {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
  }

  .not-found-content {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      // position: absolute;
      // left: 50%;
      // top: 50%;
      // transform: translate(-50%, -100%);
    }

    .text-notfound {
      @include font(500, 16px, #7d7d7d);
      margin-top: 24px;

      text-align: center;
    }
  }
}
.search-notfound {
  width: 100%;
  background: #ffffff;
  height: calc(100vh - 56px - 27px);
}

.img-notfound {
  position: relative;
  // width: 81px;
  // height: 73px;

  .search-none {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 73px;
    height: 73px;
    object-fit: cover;
  }

  .x-none {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 33px;
    left: 0px;
  }
}
