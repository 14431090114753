@import "scss/mixins";
@import "scss/variables";

.market-home-view-page {
  min-height: 100vh;
  position: relative;
  padding-bottom: 100px;

  .button__cover-img--circle {
    border: none;
    outline: none;

    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
    color: white;

    max-width: 50px;
    max-height: 50px;
    width: 1.625rem;
    height: 1.625rem;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    padding: 6px;

    line-height: 0px;
  }

  .buttons-bar--fixed-top-right {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px 12px 0px;
    z-index: 2;

    .button-back--top-left {
      width: 26px;
      height: 26px;

      padding-left: 8px;
      padding-right: 10px;
      padding-top: 6px;
      padding-bottom: 6px;

      background-color: rgba(0, 0, 0, 0.5);

      &:focus,
      &:visited,
      &:active {
        background-color: rgba(0, 0, 0, 0.3);
      }

      &:disabled {
        background-color: rgba(0, 0, 0, 0.3);
      }

      .icon-search {
      }
    }
  }

  .buttons-bar--fixed-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 12px 0px;
    z-index: 2;

    .button-back--top-left {
      width: 26px;
      height: 26px;

      padding-left: 8px;
      padding-right: 10px;
      padding-top: 6px;
      padding-bottom: 6px;

      background-color: rgba(0, 0, 0, 0.5);

      &:focus,
      &:visited,
      &:active {
        background-color: rgba(0, 0, 0, 0.3);
      }

      &:disabled {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .content {
    padding-bottom: 20px;

    .product-section {
      background-color: $color-white;

      padding: 12px;
      margin-top: 8px;

      // nếu có 2 con
      // con thứ 2 sẽ là cụm nút ĐẶT MÓN + GIAO VỀ
      // show border ngăn với phần BEST SELLER bên trên
      .product-section-child:nth-child(2) {
        border-top: 0.5px solid $color-grey_gainsboro;
        margin-top: 12px;
        padding-top: 16px;
      }

      .action-buttons__container--skeleton {
        span {
          display: block;
          width: 100%;
          height: 40px;
          border-radius: 20px;
        }
      }

      .action-buttons__container {
        display: flex;
        align-items: center;

        padding: 4px 0px;

        & > :not(:first-child) {
          margin-left: 12px;
        }

        .action-button {
          flex-grow: 1;

          background: $color-gradient_orange-yellow;
          color: $color-white;
          font-size: 13px;
          font-weight: 600;
          line-height: 0px;

          border-radius: 20px;
          padding: 10px;

          display: flex;
          align-items: center;
          justify-content: center;

          i {
            margin-right: 10px;
          }
        }
      }

      .divider-line {
        border-top: 0.5px solid $color-grey_gainsboro;
        margin: 12px -12px;
      }

      .best-seller__container {
        .label {
          font-weight: 600;
          font-size: 16px;
          color: $color-black;
        }

        .products__container {
          margin-top: 12px;
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
          column-gap: 12px;
          row-gap: 12px;
          grid-column-gap: 12px;
        }
      }
    }

    .product-section-highlight {
      background-color: $color-white;

      padding: 12px;
      margin-top: 8px;
      position: relative;

      // nếu có 2 con
      // con thứ 2 sẽ là cụm nút ĐẶT MÓN + GIAO VỀ
      // show border ngăn với phần BEST SELLER bên trên
      .product-section-child:nth-child(2) {
        border-top: 0.5px solid $color-grey_gainsboro;
        margin-top: 12px;
        padding-top: 16px;
      }

      .action-buttons__container--skeleton {
        span {
          display: block;
          width: 100%;
          height: 40px;
          border-radius: 20px;
        }
      }

      .action-buttons__container {
        display: flex;
        align-items: center;

        padding: 4px 0px;

        & > :not(:first-child) {
          margin-left: 12px;
        }

        .action-button {
          flex-grow: 1;

          background: $color-gradient_orange-yellow;
          color: $color-white;
          font-size: 13px;
          font-weight: 600;
          line-height: 0px;

          border-radius: 20px;
          padding: 10px;

          display: flex;
          align-items: center;
          justify-content: center;

          i {
            margin-right: 10px;
          }
        }
      }

      .divider-line {
        border-top: 0.5px solid $color-grey_gainsboro;
        margin: 12px -12px;
      }

      .best-seller__container {
        overflow: auto;
        white-space: nowrap;
        position: relative;
        .label {
          font-weight: 600;
          font-size: 16px;
          color: $color-black;
        }
        .products__container::-webkit-scrollbar {
          display: none;
        }
        .products__container {
          margin-top: 12px;
          overflow: auto;
          white-space: nowrap;
          text-decoration: none;
          -ms-overflow-style: none;
          scrollbar-width: none;

          scroll-behavior: smooth;
          scroll-snap-type: inline mandatory;

          .product-item {
            display: inline-block;

            scroll-snap-align: start;
          }

          &:hover {
            .btn-prev-scroll-home {
              visibility: visible;
              display: inline-block;
              transition: all 100s ease-in-out;
            }

            .btn-next-scroll-home {
              visibility: visible;
              display: inline-block;
              transition: all 100s ease-in-out;
            }
          }

          .btn-next-scroll-home {
            background: 0 0;
            border: 0;
            justify-self: center;
            outline: 0;
            right: 0;
            position: absolute;
            cursor: pointer;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            display: none;
            width: 52px;
            height: 52px;
          }

          .btn-prev-scroll-home {
            background: 0 0;
            border: 0;
            justify-self: center;
            outline: 0;
            left: 0;
            position: absolute;
            cursor: pointer;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            display: none;
            width: 52px;
            height: 52px;
          }
          // grid-template-columns: repeat(5, 1fr);
          // grid-template-rows: auto auto;
          // grid-auto-flow: column;
        }
      }
    }
  }

  .store-detail-page__cart-bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 10;
  }

  .suggested-coupons {
    padding: 12px;
    padding-top: 0;

    .suggested-coupons__list {
      padding: 0px 8px 0px;
      max-height: 50vh;

      & > * {
        padding-top: 12px;
        padding-bottom: 12px;
      }
      & > :not(:first-child) {
        border-top: 0.5px solid $color-grey_gainsboro;
      }
    }
  }

  .store-view__rating-layout {
    // padding: 8px; // diff
    margin-top: 8px;
    margin-inline: 10px;

    .rating-content {
      border-radius: 7px;
      background-color: $color-white;
      padding: 12px 8px 8px 8px;
      margin-top: 8px;
      .add-margin-top {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .add-distance-top {
        margin-top: 40px;
        margin-bottom: 35px;
      }
      &__require-login {
        width: 100%;
        height: auto;
        &__rating-text {
          @include font(600, 16px, $color-black);
        }
        &__rating-require-login {
          @include midFlex(center, center);
          width: 100%;
          flex-direction: column;
          &__text-login {
            width: 70%;
            text-align: center;
            @include font(normal, 12px, $color-black);
            margin-top: 11.5px;
          }
          &__button {
            button {
              padding: 4px 8px;
              font-weight: 600;
              font-size: 12px;
              color: #ffffff;
              border: none;
              background: $color-gradient_orange-yellow;
              border-radius: 15px;
              text-align: center;
              margin-top: 12px;
            }
          }
        }
      }

      &__line-break {
        width: 100%;
        height: 1px;
        background-color: $color-text-gray;
        margin: 16px 0px 16px 0px;
      }

      &__see-more {
        @include midFlex(center, center);
        padding: 15px 0px 5px;
        cursor: pointer;
        p {
          margin-bottom: 0px;
          @include font(600, 12px, $color-ucla_gold);
          line-height: 16px;
          margin-right: 6px;
        }
      }
    }
  }

  .people-liked-list__container {
    min-height: 20vh;
    max-height: 400px;
    overflow: auto;

    position: relative;

    .people-liked-list {
      padding: 0px 16px 30px;

      .row-info {
        display: flex;
        align-items: center;

        padding: 7px 0px;

        .row-info__avatar {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          border-radius: 50%;

          flex-shrink: 0;
        }

        .row-info__name {
          font-size: 14px;
          font-weight: 400;
          color: $color-black;
          line-height: 19px;

          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

.product-list_header {
  @include midFlex(space-between, center);
  margin: 10px;
  .label {
    font-weight: 600;
    font-size: 16px;
    color: $color-black;
  }
}

.button-see-more-product-list {
  // width: 100%;
  background: white;
  @include midFlex(center, center);
  padding: 10px 0;
  border-radius: 8px;
  margin: 0 10px;
}

.rating-content__see-more {
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    @include font(600, 12px, #ffb600);
    margin-right: 4px;
  }
}

@media only screen and (min-width: 396px) {
  .market-home-view-page {
    .content {
      .product-section-highlight {
        .best-seller__container {
          .products__container {
            .product-item {
              width: 159.5px;
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 395px) {
  .market-home-view-page {
    min-height: 100vh;
    position: relative;
    padding-bottom: 100px;

    .content {
      padding-bottom: 20px;

      .product-section-highlight {
        background-color: $color-white;
        border-radius: 7px;

        padding: 12px;
        margin-top: 8px;

        // nếu có 2 con
        // con thứ 2 sẽ là cụm nút ĐẶT MÓN + GIAO VỀ
        // show border ngăn với phần BEST SELLER bên trên
        .product-section-child:nth-child(2) {
          border-top: 0.5px solid $color-grey_gainsboro;
          margin-top: 12px;
          padding-top: 16px;
        }

        .action-buttons__container--skeleton {
          span {
            display: block;
            width: 100%;
            height: 40px;
            border-radius: 20px;
          }
        }

        .action-buttons__container {
          display: flex;
          align-items: center;

          padding: 4px 0px;

          & > :not(:first-child) {
            margin-left: 12px;
          }

          .action-button {
            flex-grow: 1;

            background: $color-gradient_orange-yellow;
            color: $color-white;
            font-size: 13px;
            font-weight: 600;
            line-height: 0px;

            border-radius: 20px;
            padding: 10px;

            display: flex;
            align-items: center;
            justify-content: center;

            i {
              margin-right: 10px;
            }
          }
        }

        .divider-line {
          margin: 0px; // reset

          border-top: 0.5px solid $color-grey_gainsboro;
          margin: 12px -12px;
        }

        .best-seller__container {
          .label {
            font-weight: 600;
            font-size: 16px;
            color: $color-chinese_black;
          }

          .products__container {
            margin-top: 12px;
            // margin-left: 16px;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
            column-gap: 12px;
            row-gap: 12px;
          }
        }
        .btn-prev-scroll-home {
          visibility: hidden;
          position: absolute;
          bottom: 0;
        }

        .btn-next-scroll-home {
          visibility: hidden;
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
}
