.close-button {
  position: absolute;
  right: 0;
  top: 0;
}

.avatar-img-modal {
  max-width: 100%;
  max-height: 400px;
}
