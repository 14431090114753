.delivery-info__wrapper {
  margin-top: 16px;
}

.delivery-info__heading {
  font-weight: 600;
  font-size: 16px;
  color: #000;

  margin-bottom: 8px;
}

.delivery-info-methods__wrapper {
  & > :not(:first-child) {
    margin-top: 8px;
  }
}

.default-address {
  display: flex;
  align-items: center;
  padding: 10px 0 16px 0;
}

.search-results__list {
  border-bottom: 1px solid #d6d6d6;
  border-left: 1px solid #d6d6d6;
  border-right: 1px solid #d6d6d6;
  padding: 8px;
  margin-bottom: 16px;

  .address-item-search {
    border-bottom: 1px solid #d6d6d6;
    padding: 7px 0 7px 0;
  }
}

.delivery-info-method {
  padding: 16px;
  background: #fff;
  border-radius: 7px;

  .delivery-info-method__collapse-button {
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;
    }
  }

  .delivery-info-method__heading {
    font-weight: 400;
    font-size: 15px;
    color: #151515;
  }

  .delivery-info-method__content-wrapper {
    margin-top: 16px;
  }
}

.address-options__wrapper {
  & > :not(:first-child) {
    margin-top: 8px;
  }
}

.delivery-info-method--enter-your-address {
  .delivery-info-method--enter-your-address__title {
    font-weight: 500;
    font-size: 12px;
    color: #000000;

    .hight-light-marker {
      font-weight: 600;
      color: red;
    }
  }

  .delivery-info-method--enter-your-address__address-input-box {
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 12px 10px;
    background: #ffffff;
    border: 1px solid #d6d6d6;
    box-sizing: border-box;
    border-radius: 7px;

    display: flex;
    align-items: center;

    .receiver-address {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      font-size: 14px;
      font-weight: 500;
      color: #151515;

      flex-grow: 1;
    }

    .receiver-address__placeholder {
      font-size: 14px;
      font-weight: 500;
      color: #b8b8b8;
    }

    .contacts-book {
      margin-left: 16px;
      border: none;
      outline: none;
      background: transparent;

      padding: 0;
      line-height: 0px;

      flex-shrink: 0;
    }
  }

  .delivery-info-method--enter-your-address__address-notes {
    margin-top: 12px;

    span {
      font-weight: 500;
      font-size: 12px;
      color: #000;
    }
  }
}
