@import "scss/variables";

.promotion-page {
  .promotion-list {
    margin-bottom: 2rem;
    white-space: nowrap;
    overflow-x: scroll;

    & > :not(:last-child) {
      margin-right: 20px;
    }
  }

  .promotion-item {
    background-color: #ffffff;
    padding: 7px;
    position: relative;
    border-radius: 3px;
    border-left: 3px solid $color-ucla_gold;
  }

  .vertical-dashed-separator {
    border-left: 1px dashed #dedede;
  }

  .button-apply {
    border: none;
    width: 8ch;
    outline: none;
    background-color: #ffffff;
  }

  .dotted-right-border {
    position: absolute;
    // right: -7px;
    // bottom: 10px;
    // top: 10px;
    // border-right: 10px dotted #ebedef;
    right: -5px;
    bottom: 7px;
    top: 7px;
    border-right: 7px dotted #ebedef;
  }

  .promotion-item-applied {
    background-color: $color-cosmic_latte;
    // retrieve 1px for border, except the left border
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 6px;
    border: 1px solid $color-ucla_gold;

    border-left: 3px solid $color-ucla_gold;

    .vertical-dashed-separator {
      border-left: 1px dashed $color-ucla_gold;
    }

    .button-apply {
      background-color: $color-cosmic_latte;
    }

    .dotted-right-border {
      border-right-color: $color-ucla_gold;
    }

    .dotted-right-border-cover {
      // right: -8px;
      right: -6px;
      border-right-color: $bgr-app;
    }

    .redundant-dotted-cover {
      width: 10px;
      position: absolute;
      right: -11px;
      bottom: 0px;
      top: 0px;
      background-color: $bgr-app;
    }
  }

  .promo-list {
    & > :not(:last-child) {
      margin-bottom: 0.5rem;
    }
    .no-coupon-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 197px;
      &__text {
        display: flex;
        text-align: center;
        margin-top: 24px;
      }
    }
  }

  .promotion-list--horizontal {
    .promotion-item {
      width: 300px;
      max-width: 80vw;
    }
  }
}
