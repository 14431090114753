@import "scss/variables";

.applied-coupon__container {
  padding-top: 4px;
  padding-bottom: 4px;

  .btn-add-coupon {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    color: $color-ryb_green;
  }

  .applied-coupon--failed {
    background-color: $color-light_pink;
    color: $color-red_lust;
  }

  .applied-coupon--succeeded {
    background-color: $color-ivory;
    color: $color-ryb_green;
  }

  .content-wrapper {
    display: flex;
    align-items: center;

    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -12px;
    margin-right: -12px;

    .applied-coupon__name {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 14px;
      margin-right: auto;
    }

    .applied-coupon__discount-amount {
      margin-left: 16px;
      font-weight: 600;
      font-size: 14px;
    }

    .btn-remove-coupon {
      outline: 0;
      border: 0;
      padding: 0;
      margin-left: 12px;
      margin-right: 8px;

      flex-shrink: 0;

      display: flex;
      align-items: center;
    }
  }

  .button-suggest-add-coupon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
