@import "scss/variables.scss";
@import "scss/mixins";

.store-detail-header_container {
  position: relative;
  background: $color-white;

  .store-banner_fnb {
    // height: 350px; ?? sao ko ta?

    background-color: $color-white;

    z-index: 1;

    .store-info__slider-img {
      object-fit: fill;
      width: 100%;
      // height: 350px;
      // height: 100vw;
      max-height: 350px;
    }

    // only to custom .carousel-dot
    .store-info__slider {
      .carousel-dots {
        // bottom: 70px; // + 1 phần bị che bởi store info card + phần nổi lên của store info card
      }
    }
  }

  .store__head__main-content {
    position: relative; // *** the key is here
    z-index: 2; // must be higher than banner

    padding-bottom: 8px;

    .store-info-card__tags {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      overflow: auto;

      padding-top: 12px;
      padding-left: 12px;

      & > :not(:first-child) {
        margin-left: 6px;
      }

      & > :last-child {
        margin-right: 12px;
      }

      .tag {
        padding: 2px 8px;
        border-radius: 4px;

        max-width: 90vw;
        flex-shrink: 0;

        background-color: $color-cosmic_latte;
        color: $color-black;
        font-size: 12px;
        font-weight: 400;

        // ** để ràng buộc chỉ show 2 line trong 1 flex item + có ràng max width ** = 2* font-size, sau đó canh chỉnh cho phù hợp
        line-height: 16px;

        // * rule hiện background như của text 2 dòng, căn giữa text theo vertical nếu text chỉ có 1 dòng
        height: 36px; // line-height(16px)*2 + padding-top(2px) + padding-bottom(2px)

        display: flex;
        align-items: center;
      }
    }

    .store-info-card__container_1 {
      background-color: $color-white;
      box-shadow: 2px 2px 20px 0px;

      padding: 0px 12px 12px;

      .store-info-card__content-wrapper {
        position: relative;

        .store-info-card__top--over-top {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          transform: translateY(-15px);

          display: flex;
          align-items: center;
          justify-content: space-between;

          margin-bottom: 8px;

          .merchant-class {
            background: $color-green_apple;
            padding: 6px 8px;
            border-radius: 4px;

            font-size: 12px;
            font-weight: 500;
            color: $color-white;

            // only 1 line
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            i {
              margin-right: 6px;
            }
          }

          .button-info {
            margin-left: auto;

            width: 32px;
            height: 32px;

            box-shadow: none;
          }
        }

        .store-info-card__body {
          padding-top: 20px;
          position: relative;
          .avatar {
            @include midFlex(center, center);
            width: 100%;
            .content-avatar {
              @include midFlex(center, center);
              border: 1px solid;
              border-color: #ffb600;
              border-radius: 50%;
              margin: 0px 0px 160px 0px;
              width: 165px;
              height: 165px;
              margin-bottom: 120px;
              .avatar-img {
                width: 164px;
                height: 164px;
                border-radius: 50%;
              }
            }
          }
          .name-store {
            margin-top: 30px;
            b {
              font-size: 16px;
              color: black;
              text-align: center;
              text-overflow: ellipsis;
            }
          }

          // layout chung
          .pl-16px {
            padding-left: 16px;
          }
          .ml-n-12px {
            margin-left: -12px;
          }

          .divider-line {
            border-top: 0.5px solid $color-grey_gainsboro;
            margin-top: 12px;
            margin-bottom: 12px;
          }

          .store-info-card__row {
            display: flex;
            align-items: center;

            .store-info-card__row__left-side {
              i {
                flex-shrink: 0;
              }
            }

            .store-info-card__row__content {
              margin-left: 8px;
              margin-right: 8px;
            }

            .store-info-card__row__right-side {
              margin-left: auto;
            }
          }
          // end layout chung

          .name-button__wrapper {
            display: flex;
            @include midFlex(end, center);

            .name {
              font-size: 20px;
              font-weight: 600;
              color: $color-chinese_black;
              line-height: 27px;

              padding-right: 12px;
            }

            .button-phone {
              width: 32px;
              height: 32px;

              margin-left: auto;
            }

            .button-share {
              width: 32px;
              height: 32px;

              margin-left: auto;
            }
          }

          .rating-star__container {
            & > :not(:last-child) {
              margin-right: 5px;
            }
          }

          .avg-price {
            font-size: 12px;
            color: $color-quick_silver;
            font-weight: 500;

            margin-top: 6px;

            .avg-price__price {
              font-weight: 600;
            }
          }

          .opening-times__container {
            margin-top: 10px;

            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: $color-chinese_black;

            .opening-times__time {
              display: flex;
              align-items: center;
            }

            .opening-status--opening {
              color: $color-green_kelly;
            }

            .opening-status--closed {
              color: $color-danger;
            }

            .vertical-separator {
              width: 1px;
              height: 10px;
              background: #000;
              margin-left: 5px;
              margin-right: 5px;
              background-color: $color-grey_platinum;
              border: 1px solid $color-grey_platinum;
            }

            .opening-status,
            .comeback-time,
            .current-opening-time-range {
              font-weight: 500;
            }
          }

          .address {
            background-image: url("../../../../../../assets/svg/bg-store-address.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            margin: 12px -12px 0px;
            padding: 8px 12px;

            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: $color-chinese_black;

            .address__buttons {
              display: flex;
              align-items: center;

              & > :not(:last-child) {
                margin-right: 12px;
              }
            }

            .distance {
              color: $color-quick_silver;
            }
          }

          .suggested-coupons__container {
            margin-top: 12px;

            .suggested-coupon__info {
              min-width: 0; // for truncate text inside a flex container

              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: $color-black;

              cursor: pointer;
              flex-grow: 1;

              .coupon-date {
                font-weight: 500;
                font-size: 10px;
                line-height: 14px;
                color: $color-spanish_gray;
              }
            }
          }

          // todo: rename for more understanding
          // Nhận món ngay sau 30 phút
          .msg-text {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $color-black;
          }

          .button-see-more {
            border: none;
            outline: none;
            background: transparent;

            margin-left: auto;
            // padding: 4px 0px;
            padding: 0px 0px;

            flex-shrink: 0;

            color: $color-ucla_gold;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;

            i {
              margin-left: 4px;
            }
          }

          .button-get-coupon {
            flex-shrink: 0;

            border: none;
            outline: none;
            border-radius: 3px;
            padding: 4px 8px;
            background-color: $color-ucla_gold;

            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: $color-white;

            &:disabled {
              background-color: $sub-color;
            }
          }
        }
      }
    }
  }
}
