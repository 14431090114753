@import "scss/mixins";
.alert-content {
  position: fixed;
  bottom: 50%;
  left: 50%;
  transform: translateY(100%);
  transition-duration: 0.4s;

  border-radius: 2px;

  width: 40%;
  margin-left: -20%;
  //   margin-top: 100px;
  height: fit-content;
  z-index: 11;

  background-color: white;

  .alert__top {
    display: flex;
    align-items: center;

    .drag-indicator {
      width: 30px;
      height: 3px;
      background: #e4e4e4;
      border-radius: 2px;
      margin-top: 10px;
      margin-bottom: 12px;
      margin-left: auto;
      margin-right: auto;
    }

    .btn-close {
      outline: none;
      border: none;
      background: transparent;
      padding: 4px;
      border-radius: 50%;
    }
  }

  .alert__children {
    // max-height: calc(100vh - 70px - 38px - 150px);

    overflow: scroll;
    border-radius: 7px;
    padding: 0 15px 15px 15px;
    color: #000;
  }

  .action_alert {
    // display: flex;
    @include midFlex(space-between, center);
    padding: 0 15px 10px 15px;

    .button_cancel {
      border: 1px solid transparent;
      color: #000000d9;
      padding: 4px 15px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 14px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 2px;
      box-shadow: 0 2px #00000004;
      background-color: #fff;
      border-color: #d9d9d9;
    }

    .button_cancel:hover {
      border: 1px solid #40a9ff;
      color: #40a9ff;
    }

    .button_accept {
      border: none;
      color: #fff;
      background-color: #1890ff;
      padding: 4px 15px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 14px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 2px;
    }
    .button_accept:hover {
      background-color: #40a9ff;
    }
  }

  .alert__title {
    padding: 10px;
    text-align: center;
    h5 {
      color: black;
    }
  }
}

.show-alert {
  transform: translateY(0);
}
.hide-alert {
  transform: translateY(100%);
}

.alert__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.3s, opacity 0.3s linear;
}

.alert__overlay--show {
  opacity: 0.5;
  visibility: visible;
}
