.alert {
  border-radius: 0.3rem;
  outline: none;
}

.alert__header {
  font-size: 14px;
  font-weight: 700;
  padding: 1rem;

  color: #151515;
}

.alert__content {
  font-size: 14px;
  font-weight: 400;
  padding: 0rem 1rem;
}

.alert__footer {
  padding: 12px;
  text-align: end;

  button {
    border: 0;
    outline: none;
    background: transparent;

    padding: 6px 12px;
    margin: 4px;
    font-size: 14px;
    font-weight: 700;

    color: #151515;
  }
}
