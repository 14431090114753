@import "scss/variables";

.suggested-coupon-item {
  display: flex;
  align-items: center;

  i {
    margin-right: 12px;
  }

  .content-wrapper {
    min-width: 0;
    flex-grow: 1;
    cursor: pointer;
    margin-right: 8px;

    .name {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 14px;
      color: $color-black;
    }

    .end-date-time {
      font-weight: 500;
      font-size: 12px;
      color: $color-granite_gray--darker;
    }
  }

  .button-save-coupon {
    white-space: nowrap;
    border: none;
    border-radius: 4px;
    padding: 2px 8px;
    margin-left: 3px;
    min-width: 7ch;
    background: $color-gradient_orange-yellow;

    &:disabled {
      background: $color-silver_sand;
    }

    span {
      font-weight: 600;
      font-size: 12px;
      color: $color-white;
    }
  }
}
