@import "scss/variables";

.search-box {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(187, 187, 187, 0.25);
    border-radius: 16px;
    padding: 9px 13px;
    padding-right: 0px;
    margin-left: 2rem;
    width: 100%;
    display: flex;
    align-items: center;

    img {
        margin-right: 9px;
    }

    .debounce-input {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
    }
}

.store-header__bottom-line {
    width: 100%;
    height: 2px;
    background: linear-gradient(220.97deg, #ffd300 -5.25%, #fa8b51 147.32%);
}

.search-box__btn-search {
    border: none;
    outline: none;
    height: 100%;
    flex-shrink: 0;
    width: 30px;

    background: linear-gradient(180deg, #ffd300 0%, #fa8b51 100%);
    border-radius: 0px 8px 8px 0px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.button__cover-img--circle--white {
    line-height: 0px; // Avoid additional height

    outline: none;
    border: none;
    flex-shrink: 0;

    padding: 6px;

    border-radius: 50%;
    background: #fff;

    &>img {
        width: 14px;
        height: 14px;
    }
}

::placeholder {
    color: #d2d2d2;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.store-header {
    background-color: #ffca26;
    background-image: url("../../../../../assets/img/bgr-store-header.png");
    // background-image: url("../../../../../assets/svg/background-header.svg");

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    padding: 15px 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    box-shadow: 0px -25px 30px 12px rgba(0, 0, 0, 0.5);
}

.store-header--searching {
    padding: 10px 16px;
    justify-content: initial;
}

.filter-button {
    width: 25px;
    height: 25px;

    img {
        width: 21px;
        height: 21px;
    }
}