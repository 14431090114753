@import "scss/mixins";
@import "scss/variables";

.rating-content__comment-sub-cmt {
  padding-left: -10px;
  padding-right: -10px;
  &__header {
    @include midFlex(space-between, center);
    &--left {
      @include midFlex(space-between, flex-start);
      &__avatar {
        margin-right: 12px;
        cursor: pointer;
        .avatar-img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
      &__information {
        @include midFlex(center, flex-start);
        flex-direction: column;
        h2 {
          @include font(500, 14px, $color-black);
          line-height: 19px;
          margin-bottom: 2px;
        }
        &--datetime {
          p {
            @include midFlex(flex-start, center);
            @include font(400, 10px, #8a8a8a);
            line-height: 14px;
            margin-bottom: 0px;
            span {
              @include font(500, 10px, $color-green-text);
              line-height: 14px;
              margin-left: 4px;
            }
          }
        }
        &--star {
          line-height: 16px;
          flex-shrink: 0;
          .star-list {
            & > :not(:first-child) {
              margin-left: 3px;
            }
          }
        }
      }
    }
  }
  &__body {
    &__main-text {
      margin-top: 10px;
      margin-bottom: 10px;
      p {
        @include font(normal, 14px, $color-black);
        margin-bottom: 0px;
        word-break: break-word;
      }
    }
    &__heart-reply {
      @include midFlex(space-between, center);
      &--heart-side {
        cursor: pointer;
        @include midFlex(flex-start, center);
        p {
          margin-bottom: 0px;
          margin-left: 6px;
        }
      }
      &--reply-side {
        margin-left: auto;

        p {
          margin-bottom: 0px;
        }
      }
    }
    &__heart-reply-user {
      @include midFlex(space-between, center);
      margin-top: 9px;
      flex: 1;
      padding: 8px 0px 8px 0px;
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
      &--like-side-user {
        flex-grow: 1;
        @include midFlex(center, center);
        img {
          cursor: pointer;
          .active {
            color: $color-danger;
          }
        }
        span {
          margin-left: 8px;
          cursor: pointer;
          &:active {
            color: #ffb600;
          }
        }
      }
      &--reply-side-user {
        flex-grow: 1;
        @include midFlex(center, center);
        span {
          cursor: pointer;
          margin-left: 8px;
        }
      }
    }
    &__user-reply-text {
      &--prev-text {
        margin-top: 12px;
        cursor: pointer;
        h5 {
          @include font(600, 12px, $color-black);
          line-height: 16px;
        }
      }
      &__user-cmt {
        margin-top: 12px;
        display: flex;
        justify-content: flex-start;
        &__avatar {
          flex-shrink: 0;
          margin-right: 8px;
          cursor: pointer;

          .avatar-img {
            border-radius: 50%;
            width: 32px;
            height: 32px;
          }
        }
        &__comment {
          @include midFlex(center, flex-start);
          flex-direction: column;
          width: 100%;
          position: relative;
          &--user {
            background-color: $color-cmt-gray;
            padding: 8px 10px 14px;
            border-radius: 7px;
            width: 100%;
            h5 {
              @include font(500, 15px, $color-black);
              margin-bottom: 0px;
            }
            p {
              margin-bottom: 0px;
              @include font(normal, 13px, $color-black);
            }
            &__popup-heart {
              @include midFlex(center, center);
              background-color: $color-white;
              width: 100%;
              max-width: 49px;
              height: 24px;
              box-shadow: 0px 4px 6px rgba(90, 90, 90, 0.25);
              position: absolute;
              right: 5vw;
              border-radius: 15px;
              p {
                font-family: Inter;
                @include font(normal, 12px, $color-mixed-black);
                min-width: 1ch;
                margin-left: 3px;
              }
            }
          }
          &__button {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 8px;
            p {
              margin-bottom: 0px;
            }
            .--time {
              @include font(500, 12px, $color-mixed-black);
            }
            .--bullet {
              color: $bullet-black;
              width: 100%;
              max-width: 3px;
              margin-top: 1px;
              margin-left: 10px;
              margin-right: 10px;
            }
            .--like-btn {
              @include font(600, 13px, $color-mixed-black);
              line-height: 16px;
              cursor: pointer;
            }
            .--like-active {
              @include font(600, 13px, $color-ucla_gold);
              line-height: 16px;
              cursor: pointer;
            }
          }
        }
      }
      // &__text-box {
      //   display: flex;
      //   justify-content: flex-start;
      //   margin-top: 16px;
      //   &__avatar {
      //     img {
      //       width: 32px;
      //       height: 32px;
      //       border-radius: 16px;
      //     }

      //     margin-right: 11px;
      //   }
      //   &__comment {
      //     @include midFlex(center, flex-start);
      //     flex-direction: column;
      //     width: 100%;
      //     &--user {
      //       background-color: $color-cmt-gray;
      //       padding: 10px 16px 9px;
      //       border-radius: 20px;
      //       width: 100%;
      //       input {
      //         width: 100%;
      //         border: none;
      //         background-color: $color-cmt-gray;
      //         &:focus {
      //           outline: none;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}
