.address-item__wrapper {
  // padding: 10px 14px;

  display: flex;
  align-items: center;

  background: #fff;

  .address-item__address {
    font-size: 13px;
    font-weight: 400;
    color: #616161;

    min-width: 0;
  }

  .address-item__address--brief {
    font-size: 14px;
    font-weight: 500;
    color: #151515;
    margin-bottom: 4px;
  }

  .address-item__recent-used-icon {
    margin-right: 22px;
    flex-shrink: 0;
  }

  .default-address-sign {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;

    color: #fa6400;

    margin-top: 3px;

    & > span {
      margin-left: 7px;
    }
  }
}

.address-item__wrapper--disabled {
  opacity: 0.3;
}
