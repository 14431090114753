@import "scss/variables";
@import "scss/mixins";

.store-detail-page {
  .store-header__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }
  // horizontal food list
  .horizontal-food-list {
    & > * {
      margin-right: 2.8vw;
      min-width: 128px; // must have width in pixel for title truncation
      max-width: 135px;
    }
  }

  .grid__food-item-container--fill {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    column-gap: 0.625rem;
    -webkit-column-gap: 0.625rem;
    -moz-column-gap: 0.625rem;
    row-gap: 1.5rem;
  }

  .store-detail-page__cart-bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 10;
  }
}

.product-list_content {
  position: relative;
}

.suggested-coupon-list {
  & > * {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  & > :not(:first-child) {
    border-top: 0.5px solid #dedede;
  }
}

.color-fef7ed {
  background: #fef7ed;
}
