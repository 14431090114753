@import "scss/variables";

.button__add-another {
  border: 1px solid $color-ucla_gold;
  border-radius: 12px;
  padding: 8;
}

// buttons
.button_small {
  padding: 0.5rem;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  height: 22px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: white;
}

.button_small--disabled {
  opacity: 0.5;
}

.list-items {
  border-top: 0.5px solid #dedede;
  padding-bottom: 25px;
  max-height: 50vh;
  overflow-y: overlay;

  & > :not(:first-child) {
    border-top: 0.5px solid #dedede;
  }
}
