@import "scss/variables.scss";

.common-input-number {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;

  .btn-change-number {
    flex-shrink: 0;

    border: none;
    outline: none;
    border-radius: 50%;

    padding: 0px;
    margin: 0px;
    height: 1.625em;
    width: 1.625em;

    background: $color-gradient_orange-yellow;

    position: relative;
    line-height: 0px;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .btn-change-number--disabled {
    background: $color-gradient--gray;
    cursor: not-allowed;
  }

  input {
    border: none;
    outline: none;
    text-align: center;
    color: #000000;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
