.popup-content {
    position: fixed;
    bottom: 0px;
    left: 0px;
    transform: translateY(100%);
    transition-duration: 0.4s;

    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    width: 100%;
    height: fit-content;
    z-index: 51;

    background-color: white;

    .popup__top {
        display: flex;
        align-items: center;
        height: 19px;

        .drag-indicator {
            width: 32px;
            height: 3px;
            background: #e4e4e4;
            border-radius: 2px;
            margin-top: 10px;
            margin-bottom: 12px;
            margin-left: auto;
            margin-right: auto;
        }

        .btn-close {
            outline: none;
            border: none;
            background: transparent;
            padding: 4px;
            border-radius: 50%;
        }
    }

    .popup__children {
        max-height: calc(100vh - 70px - 38px - 150px);

        overflow: scroll;
        border-radius: 7px;
    }

    .popup__title {
        padding-bottom: 4px;
    }
}

.show-popup {
    transform: translateY(0);
}

.hide-popup {
    transform: translateY(100%);
}

.popup__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    background-color: #000;
opacity: 0;
visibility: hidden;
transition: visibility 0.3s,
opacity 0.3s linear;
}

.popup__overlay--show {
    opacity: 0.5;
    visibility: visible;
}