@import "scss/mixins";

.delivery-wrapper {
  max-height: 85vh;
  margin-bottom: 50px;
  .title-list {
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    color: black;
  }
  .list-item {
    padding-inline: 16px;
    margin-top: 15px;
    .type-delivery {
      @include midFlex(space-between, center);
      background-color: rgb(247, 247, 247);
      padding: 10px;
      border-radius: 8px;
      margin-bottom: 8px;
      .type-delivery_left {
        @include midFlex(center, center);
        .icon-delivery {
          width: 40px;
        }
        .text-delivery {
          color: black;
        }
      }
    }
    .type-delivery-select {
      border: solid 1px rgb(255, 182, 0);
    }
  }
}
