@import "scss/variables";
@import "scss/mixins";
$color-border-gray: #e3e3e3;

.search-page-search {
  padding-bottom: 70px;
}

.product-section {
  background-color: $color-white;
  height: 100%;

  padding: 12px;
  margin: 10px;
  border-radius: 8px;

  // nếu có 2 con
  // con thứ 2 sẽ là cụm nút ĐẶT MÓN + GIAO VỀ
  // show border ngăn với phần BEST SELLER bên trên
  .product-section-child:nth-child(2) {
    border-top: 0.5px solid $color-grey_gainsboro;
    margin-top: 12px;
    padding-top: 16px;
  }

  .action-buttons__container--skeleton {
    span {
      display: block;
      width: 100%;
      height: 40px;
      border-radius: 20px;
    }
  }

  .action-buttons__container {
    display: flex;
    align-items: center;

    padding: 4px 0px;

    & > :not(:first-child) {
      margin-left: 12px;
    }

    .action-button {
      flex-grow: 1;

      background: $color-gradient_orange-yellow;
      color: $color-white;
      font-size: 13px;
      font-weight: 600;
      line-height: 0px;

      border-radius: 20px;
      padding: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      i {
        margin-right: 10px;
      }
    }
  }

  .divider-line {
    border-top: 0.5px solid $color-grey_gainsboro;
    margin: 12px -12px;
  }

  .best-seller__container {
    .label {
      font-weight: 600;
      font-size: 16px;
      color: $color-black;
    }

    .products__container {
      margin-top: 12px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      column-gap: 12px;
      row-gap: 12px;
    }
  }
}

.loading-wrapper {
  position: absolute;
  width: 100%;
  @include midFlex(center, center);
  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #f9b115;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
