@import "scss/variables";

.tab-list-wrapper::-webkit-scrollbar {
  display: none;
}

.tab-list-wrapper {
  margin-top: 54px;
  position: sticky;
  top: 54px;
  z-index: 5;
  width: 100%;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background: white;
  display: inline-flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .react-tabs__tab {
    display: inline-block;
    border: none;
    word-wrap: break-word;
    font-size: 13px;
    padding-right: 12px;
  }
  .react-tabs__tab--selected {
    color: #f36e20;
    font-weight: 600;
    background: none;
    border-bottom: solid 2px #f36e20;
  }
}

.product-list_wrapper {
  background-color: $color-white;
  border-radius: 8px;
  .product-list_header {
    padding-top: 12px;
    .label {
      font-weight: 600;
      font-size: 16px;
      color: $color-black;
    }
  }
  .product-section {
    background-color: $color-white;

    padding: 0 12px;

    // nếu có 2 con
    // con thứ 2 sẽ là cụm nút ĐẶT MÓN + GIAO VỀ
    // show border ngăn với phần BEST SELLER bên trên
    .product-section-child:nth-child(2) {
      border-top: 0.5px solid $color-grey_gainsboro;
      margin-top: 12px;
      padding-top: 16px;
    }

    .action-buttons__container--skeleton {
      span {
        display: block;
        width: 100%;
        height: 40px;
        border-radius: 20px;
      }
    }

    .action-buttons__container {
      display: flex;
      align-items: center;

      padding: 4px 0px;

      & > :not(:first-child) {
        margin-left: 12px;
      }

      .action-button {
        flex-grow: 1;

        background: $color-gradient_orange-yellow;
        color: $color-white;
        font-size: 13px;
        font-weight: 600;
        line-height: 0px;

        border-radius: 20px;
        padding: 10px;

        display: flex;
        align-items: center;
        justify-content: center;

        i {
          margin-right: 10px;
        }
      }
    }

    .divider-line {
      border-top: 0.5px solid $color-grey_gainsboro;
      margin: 12px -12px;
    }

    .best-seller__container {
      .label {
        font-weight: 600;
        font-size: 16px;
        color: $color-black;
      }

      .products__container {
        margin-top: 12px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        column-gap: 12px;
        row-gap: 12px;
      }
    }
  }
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #f9b115;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
}
