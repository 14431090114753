@import "scss/variables.scss";
@import "scss/mixins.scss";

.store__head__container {
  position: relative;
  background: $color-white;

  .store-banner {
    // only to custom .carousel-dot
    .store-info__slider {
      .carousel-dots {
        bottom: 70px; // + 1 phần bị che bởi store info card + phần nổi lên của store info card
      }
    }
  }

  .store-info-card {
    position: relative;
    z-index: 1;
    margin-top: -40px; // đè lên slider 1 khoảng bằng 40px theo design
    padding-bottom: 8px;
    &__conatiner-scroll {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      ul {
        margin-bottom: 0;
      }
    }
    .store-info-card__tags {
      display: flex;
      align-items: center;
      list-style: none;
      max-width: 100%;
      overflow-x: scroll;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        background: transparent; /* make scrollbar transparent */
        -webkit-appearance: none;
        width: 0;
        height: 0;
      }
      padding-top: 12px;
      padding-left: 12px;

      .tag {
        padding: 2px 8px;
        border-radius: 4px;
        max-width: 90vw;
        flex-shrink: 0;
        padding: 8px;
        border-radius: 4px;
        margin: 0 4px;
        white-space: nowrap;
        background-color: $color-cosmic_latte;
        color: $color-black;
        font-size: 12px;
        font-weight: 400;

        // ** để ràng buộc chỉ show 2 line trong 1 flex item + có ràng max width ** = 2* font-size, sau đó canh chỉnh cho phù hợp
        line-height: 16px;

        // * rule hiện background như của text 2 dòng, căn giữa text theo vertical nếu text chỉ có 1 dòng
        height: 36px; // line-height(16px)*2 + padding-top(2px) + padding-bottom(2px)
        display: flex;
        align-items: center;

        @media screen and (min-width: 800px) {
          max-width: calc(0.9 * 800px);
        }
      }

      & > :not(:first-child) {
        margin-left: 6px;
      }

      & > :last-child {
        margin-right: 12px;
        max-width: calc(800px - 2 * 12px); // cho case chi co duy nhat 1 tag
      }
    }

    .store-info-card__content-wrapper {
      background-color: $color-white;
      border-radius: 7px;
      box-shadow: 2px 2px 20px 0px;
      position: relative;
      padding: 12px;
      margin: 0px 12px;

      .store-info-card__top--over-top {
        margin-top: calc(-12px - 15px);
        margin-bottom: 8px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .merchant-class {
          background: $color-green_apple;
          padding: 6px 8px;
          border-radius: 4px;

          font-size: 12px;
          font-weight: 500;
          color: $color-white;

          // only 1 line
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          i {
            margin-right: 6px;
          }
        }

        .button-info {
          margin-left: auto;
          width: 32px;
          height: 32px;
          box-shadow: none;
        }
      }

      .store-info-card__body {
        // layout chung
        .pl-16px {
          padding-left: 16px;
        }
        .ml-n-12px {
          margin-left: -12px;
        }

        .divider-line {
          margin: 0px; // reset

          margin-top: 12px;
          margin-bottom: 12px;
          border-top: 0.5px solid $color-grey_gainsboro;
        }

        .store-info-card__row {
          display: flex;
          align-items: center;

          .store-info-card__row__left-side {
            i {
              flex-shrink: 0;
            }
          }

          .store-info-card__row__content {
            margin-left: 8px;
            margin-right: 8px;
          }

          .store-info-card__row__right-side {
            margin-left: auto;
          }
        }
        // end layout chung

        .name-button__wrapper {
          display: flex;

          .name {
            flex-grow: 1;
            word-break: break-word;

            font-size: 20px;
            font-weight: 600;
            color: $color-chinese_black;
            line-height: 27px;

            padding-right: 12px;
          }

          .button-share {
            width: 32px;
            height: 32px;

            flex-shrink: 0;

            margin-left: auto;
          }
        }

        .rating-star__container {
          & > :not(:last-child) {
            margin-right: 5px;
          }
        }

        .avg-price {
          font-size: 12px;
          color: $color-quick_silver;
          font-weight: 500;

          .avg-price__price {
            font-weight: 600;
          }
        }

        .opening-times__container {
          margin-top: 10px;

          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: $color-chinese_black;

          .opening-times__time {
            display: flex;
            align-items: center;
          }

          .opening-status--opening {
            color: $color-green_kelly;
          }

          .opening-status--closed {
            color: $color-danger;
          }

          .vertical-separator {
            width: 1px;
            height: 10px;
            background: #000;
            margin-left: 5px;
            margin-right: 5px;
            background-color: $color-grey_platinum;
            border: 1px solid $color-grey_platinum;
          }

          .opening-status,
          .comeback-time,
          .current-opening-time-range {
            font-weight: 500;
          }
        }

        .address {
          background-image: url("../../../../assets/svg/bg-store-address.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;

          margin: 12px -12px 0px;
          padding: 8px 12px;

          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $color-chinese_black;

          .address__buttons {
            display: flex;
            align-items: center;

            & > :not(:last-child) {
              margin-right: 12px;
            }
          }

          .distance {
            color: $color-quick_silver;
          }
        }

        .suggested-coupons__container {
          margin-top: 12px;

          .suggested-coupon__info {
            min-width: 0; // for truncate text inside a flex container

            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $color-black;

            cursor: pointer;
            flex-grow: 1;

            .coupon-date {
              font-weight: 500;
              font-size: 10px;
              line-height: 14px;
              color: $color-spanish_gray;
            }
          }
        }

        // todo: rename for more understanding
        // Nhận món ngay sau 30 phút
        .msg-text {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $color-black;
        }

        .button-see-more {
          border: none;
          outline: none;
          background: transparent;

          margin-left: auto;
          // padding: 4px 0px;
          padding: 0px 0px;

          flex-shrink: 0;

          color: $color-ucla_gold;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;

          i {
            margin-left: 4px;
          }
        }

        .button-get-coupon {
          flex-shrink: 0;

          border: none;
          outline: none;
          border-radius: 3px;
          padding: 4px 8px;
          background-color: $color-ucla_gold;

          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: $color-white;

          &:disabled {
            background-color: $sub-color;
          }
        }
      }
    }
    .btn-next-scroll {
      display: none;
    }
    .btn-prev-scroll {
      display: none;
    }
  }
}

@include minW(800px) {
  .store-info-card {
    &__conatiner-scroll {
      &:hover {
        .btn-prev-scroll {
          display: inline-block;
          transition: all 100s ease-in-out;
        }
        .btn-next-scroll {
          display: inline-block;
          transition: all 100s ease-in-out;
        }
      }
    }
    .btn-next-scroll {
      background: 0 0;
      border: 0;
      justify-self: center;
      outline: 0;
      right: 0;
      position: absolute;
      cursor: pointer;
      top: 90%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      display: none;
      width: 52px;
      height: 52px;
    }
    .btn-prev-scroll {
      background: 0 0;
      border: 0;
      justify-self: center;
      outline: 0;
      left: 0;
      position: absolute;
      cursor: pointer;
      top: 90%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      display: none;
      width: 52px;
      height: 52px;
    }
  }
}
