@import "scss/variables.scss";

.tip-info {
  padding-top: 16px;
}

.tip-info__title {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: $color-black;
}

.tip-info__content {
  max-height: calc(85vh - 16px - 16px - 10px);
  overflow-y: overlay;
  margin: 10px 20px 0px;
  padding-bottom: 52px;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: $color-chinese_black;
}
