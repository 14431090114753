// Variable overrides
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap");
$font-body: "Inter", Arial, Helvetica, sans-serif;

// red
$bgr-danger: #eb1f1f;
$custom_bgr-danger: rgba(255, 0, 0, 0.3);
$color-danger: #eb1f1f;
$color-red_lust: #e02020;
$color-red--pigment: #f5222d;

// green
$color-ryb_green: #58ca30;
$color-slimy_green: #20a21b;
$color-green_apple: #5dc159;
$color-green_kelly: #52c41a;
$color-green--kelly--darker: #58ab00;
$color-green-text: #81ca76;
$color-green--middle: #47a359;

// white
$bgr-body: rgb(242, 242, 242);
$bgr-footer: #fef7ed;
$bgr-app: #ebedef;
$color-light_orange: #fef7ed;
$color-cosmic_latte: #fff9ea;
$color-ivory: #f8ffef;
$color-white: #ffffff;
$color-anti_flash_white: #f3f3f3;
$color-white--floral: #fffbf3;
$color-corn-silk: #fff6e0;

// grey
$color-old_silver: #828282;
$color-spanish_gray: #9b9b9b;
$color-spanish_gray--97: #979797;
$color-spanish_gray--999: #999999;
$color-granite_gray: #636262;
$color-granite_gray--darker: #646464;
$color-quick_silver: #a1a1a1;
$color-quick_silver--a4: #a4a4a4;
$bullet-black: #696969;
$color-silver_chalice: #ababab;
$color-grey_gainsboro: #dedede;
$color-grey_platinum: #e6e6e6;
$color-gray_add: #616161;
$color-grey: rgb(125, 125, 125);
$color-gray2: rgb (180, 180, 180);
$color-sub-gray: #f1f1f1;
$sub-color: #d2d2d2;
$color-silver_sand: #c4c4c4;
$color-gray--bright: #efefef;
$color-gray--bright--ec: #ececec;
$color-gray--bright--eee: #eeeeee;
$color-warm-grey: #7d7d7d;
$color-s-gray: #f2f2f2;
$color-text-gray: #dedede;
$color-cmt-gray: #f0f2f5;
$color-gray--gunmetal: #263238;

// black
$color-black: #000000;
$color-blackcl: #151515;
$color-chinese_black: #151515;
$color-mixed-black: rgba(41, 41, 41, 0.7);
$color-black--onyx: #373737;

$color-orange: #fa6400;
$color-ucla_gold: #ffb600;

// blue
$color-blue: rgb(52, 120, 245);
$color-blue--azure: #0091ff;
$color-blue--dodger: #1890ff;

// pink
$color-light_pink: #fdf4f4;

// yellow
$color-light-yellow: rgb(255, 246, 224);

// gradient
$color-linear: linear-gradient(220.97deg, #ffd300 -5.25%, #fa8b51 147.32%);
$color-gradient_orange-yellow: linear-gradient(
  220.97deg,
  #ffd300 -5.25%,
  #fa8b51 147.32%
);
$color-gradient--gray: linear-gradient(
  243.97deg,
  #c1c1c1 16.41%,
  #979797 83.59%
);

// orange
$color-orange-amber: #ff7d00;

// dimensions
$max-width__main-container: 800px;
$small-image-height: 3.5rem;
$gallery-gap: 0.5rem;

/*GRID - media queries breakpoints*/
// $xxs-min: 340px;
$xxs-min: 576px;
$xs-min: 768px;
$sm-min: 992px;
$md-min: 1200px;
$lg-min: 2000px;

$xxs-max: ($xxs-min - 1);
$xs-max: ($xs-min - 1);
$sm-max: ($sm-min - 1);
$md-max: ($md-min - 1);
$lg-max: ($lg-min - 1);
