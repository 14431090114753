.only-2-lines {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.strike-low {
  position: relative;
  white-space: nowrap; /* would center line-through in the middle of the wrapped lines */
}

.strike-low:after {
  border-top: 1px solid #9b9b9b;
  position: absolute;
  content: "";
  right: 0;
  top: 55%;
  left: 0;
}

.store-detail__food-item__wrapper-market {
  .food-image__wrapper {
    position: relative;
    .food-image {
      border-radius: 7px;
      object-fit: fill;
      height: 140px;
      width: 100%;
      margin-bottom: 0.5rem;
    }
    // img {
    //   border-radius: 7px;
    //   object-fit: cover;
    //   height: 140px;
    //   width: 100%;
    //   margin-bottom: 0.5rem;
    // }
  }
  .icon-plus {
    height: 26px;
    width: 26px;
    object-fit: contain;
    position: absolute;
    bottom: 23px;
    right: 7px;
  }
  .icon-saleTagUrl {
    height: 40px;
    width: 116px;
    object-fit: contain;
    position: absolute;
    bottom: 23px;
    left: 4px;
  }
}

.food-category {
  background: "#fee196";
  .category-name {
    margin-bottom: 0px !important;
  }
  p {
    font-weight: 600;
  }
}

.sale-img {
  height: 32px;
  width: 30px;
  object-fit: contain;
  position: absolute;
  top: 0;
  right: 7px;
}

.txt-sale {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 10px;
}

.item-disabled {
  opacity: 0.6;
}
