@import "scss/variables.scss";

$clr-price--current: $color-orange;
$clr-price--old: $color-spanish_gray;

.feed-card {
  border-radius: 7px;
  background: $color-white;

  padding: 16px;
  padding-bottom: 14px;

  // use filter will cause bad performance
  // filter: drop-shadow(
  //   0px 4px 4px rgba(0, 0, 0, 0.15)
  // ); // not box-shadow to have shadow in ticket dots
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.15);

  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;

  &--clickable {
    &:hover {
      cursor: pointer;

      // use filter will cause bad performance
      // filter: drop-shadow(
      //   0px 8px 8px rgba(0, 0, 0, 0.15)
      // ); // not box-shadow to have shadow in ticket dots
      box-shadow: 0 5px 8px 0px rgba(0, 0, 0, 0.15);

      transform: translateY(-4px);
    }
  }

  .feed-card__hot-vouchers-container {
    margin-top: 8px;

    & > :not(:first-child) {
      margin-top: 6px;
    }
  }

  .feed-card__hot-voucher {
    display: flex;
    align-items: center;

    line-height: 14px;
    font-size: 12px;
  }

  .feed-card__hot-voucher__icon {
    flex-shrink: 0;
    line-height: 0px;
  }

  .feed-card__hot-voucher__price {
    &--current {
      margin-left: 6px;

      font-weight: 600;
      color: $clr-price--current;
      white-space: nowrap;
    }
    &--old {
      margin-left: 3px;
      font-weight: 500;
      color: $clr-price--old;
    }
  }
  .feed-card__hot-voucher__name {
    margin-left: 6px;
    font-weight: 400;
    color: $color-chinese_black;
    line-height: 14px;
  }

  .feed-card__gallery {
    display: grid;
    grid-template-columns: 216fr 104fr;
    grid-template-rows: 56px 56px;
    // grid-template-areas:
    //   "spotlight sub-top"
    //   "spotlight sub-bottom";
    column-gap: 4px;
    row-gap: 4px;

    margin-top: 8px;

    object,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 7px;
    }
  }

  .feed-card__gallery--darken {
    object,
    img {
      filter: brightness(0.5);
    }
  }

  .feed-card__gallery--spotlight {
    // grid-area: spotlight;
    grid-area: 1/1/3/1; // row1/col1 -> row2/col1
    position: relative;
    .feed-card-percent {
      position: absolute;
      width: 32px;
      height: 32px;
      background: linear-gradient(180deg, rgb(255, 90, 90), rgb(255, 19, 19));
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-weight: 600;
      font-size: 10.5px;
      top: 8px;
      right: 8px;
    }
  }
  .feed-card__gallery--top {
    // grid-area: sub-top;
    grid-area: 1/2/2/2;
  }
  .feed-card__gallery--bottom {
    // grid-area: sub-bottom;
    grid-area: 2/2/3/2;
  }

  .feed-card__gallery--spotlight__tags-container {
    display: flex;
    align-items: center;

    position: absolute;
    left: 8px;
    bottom: 8px;
    right: 8px;

    & > * {
      background: rgba(0, 0, 0, 0.5);
      padding: 2px 6px;

      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $color-white;
    }

    & > :not(:first-child) {
      margin-left: 4px;
    }
  }

  .__distance-tag {
    display: flex;
    align-items: center;

    border-radius: 2px;

    span {
      margin-left: 4px;
    }
  }
  .__merchant-tag {
    border-radius: 4px;
  }

  .feed-card__btn-show-all-store {
    display: flex;
    align-items: center;

    padding: 12px 0px 14px;
    margin-bottom: -14px; // margin bottom âm và thêm padding để dễ nhấn nút hơn
    border: 0;
    outline: none;
    background-color: transparent;

    &__text {
      font-weight: 500;
      font-size: 12px;
      color: $color-chinese_black;
      line-height: 14px;

      margin-left: 6px;
      margin-right: 4px;
    }

    &__arrow {
      line-height: 8px;
    }
  }

  //!!!

  .feed-card__top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: $color-white;
  }

  .feed-card__top__cover {
    position: relative;

    .btn-show-list-stores {
      display: flex;
      align-items: center;

      position: absolute;
      right: 0;
      bottom: -14px;

      border-radius: 4px 0px 0px 4px;
      padding: 6px 7px;
      background-color: $color-green--middle;

      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: $color-white;

        margin-right: 4px;
      }

      &:active {
        transform: none;
      }
    }
  }

  .feed-card__top__cover__cover-img {
    display: block;
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    background: url("../../../../assets/img/feed-img-default--border-radius-0.jpg");
  }

  .feed-card__top__cover__cover-img--darken {
    filter: brightness(0.5);
  }

  .feed-card__info {
    // padding: 18px 12px 6px;

    display: flex;
    align-items: center;

    .logo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      flex-shrink: 0;

      align-self: flex-start;
    }

    .title {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.25px;
      color: $color-black;
    }

    .nearest-store__icon {
      flex-shrink: 0;
      line-height: 14px;
      margin-right: 4px;
    }

    .nearest-store__name {
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.2px;
      color: $color-chinese_black;
      line-height: 16px;
    }

    .statistic {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      margin-top: 2px;
    }

    .statistic__avg-rate {
      display: flex;

      .statistic__avg-rate__stars {
        margin-left: 0 !important;
        align-self: flex-start;
        line-height: 12px;
      }

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: $color-chinese_black;

        margin-left: 4px;
      }
    }

    .statistic__divider {
      width: 1px;
      height: 7px;
      background-color: $color-grey_platinum;
      border-left: 0.5px solid $color-grey_platinum;

      margin: 0px 4.5px;
    }

    .statistic__avg-price {
      display: flex;
      align-items: baseline;
      white-space: nowrap;

      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.17px;
        color: $color-quick_silver;
      }

      .statistic__avg-price__price {
        font-weight: 600;
        margin-left: 2px;
      }
    }

    .opening-time-info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      margin-top: 2px;
    }

    .opening-time-info__status {
      //
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.2px;
      color: $color-green_kelly; // useless

      &--closed {
        color: $color-red--pigment;
      }
    }

    .opening-time-info__comeback {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.2px;
      color: $color-chinese_black;
    }

    .opening-time-info__comeback__time {
      font-weight: 600;
    }

    .opening-time-info__divider {
      width: 1px;
      height: 12px;
      background-color: $color-grey_platinum;
      border-left: 0.5px solid $color-grey_platinum;

      margin: 0px 4px;
    }

    .tags {
      display: flex;
      align-items: center;
      margin-top: 4px;
      overflow: hidden;
      flex-wrap: wrap;
      max-height: 16px;

      .tag {
        background: $color-corn-silk;
        border-radius: 2px;
        padding: 0px 8px;

        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.15px;
        color: $color-black;

        white-space: nowrap;
      }

      & > :not(:first-child) {
        margin-left: 4px;
      }
    }

    .promotions {
      padding-right: 4px;

      display: flex;
      align-items: center;

      max-height: calc(18px + 4px);
      overflow-x: overlay;
      overflow-y: hidden;
      scroll-behavior: smooth;

      @media screen and (min-width: 799px) {
        flex-wrap: wrap;
        max-height: calc(2 * (18px + 4px));
        overflow: hidden;
      }

      .promotion {
        flex-shrink: 0;

        background: $color-white--floral;
        margin-top: 4px;
        padding: 1px 8px;
        padding-right: 6px; // = 8px (original design) - 3px (border)  - 1px (width of curved line)

        // if use the line below (border-left), container must have 1px of padding (due to border-image-outset)
        border-right-width: 3px; // 3px because each hole is 2x2(px), yellow stroke is 0.5px -> 3 to make sure image not be cut
        border-right-style: solid;
        border-image-source: url("../../../../assets/svg/border-tag-promotion.svg");
        border-image-slice: 3; // keep adjust until reach the  best look
        border-image-width: 3px; // keep adjust until reach the  best look
        border-image-outset: 0;
        border-image-repeat: round;
        border-radius: 3px;

        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: $color-gray--gunmetal;

        @media screen and (min-width: 799px) {
          max-width: 100%;
        }
      }

      // * prepared
      // .store-promotion--more {
      //   padding: 1px 5px;
      //   background: $color-ucla_gold;
      //   border: 0.5px solid $color-ucla_gold;
      //   border-radius: 3px;

      //   font-weight: 500;
      //   font-size: 12px;
      //   line-height: 16px;
      //   color: $color-white;
      // }

      & > :not(:last-child) {
        margin-right: 4px;
      }
    }
  }

  .feed-card__description {
    display: flex;
    align-items: flex-start;

    margin-top: 6px;
  }

  .feed-card__description__icon {
    flex-shrink: 0;
    line-height: 12px;
    margin-right: 6px;
  }

  .feed-card__description__content {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.2px;
    color: $color-chinese_black;
    line-height: 14px;
  }

  .feed-card__prices {
    margin-top: 12px;

    .dash-price {
      font-size: 20px;
      font-weight: 600;
      color: #9b9b9b;
    }

    &--selling {
      font-weight: 700;
      font-size: 15px;
      letter-spacing: 0.27px;
      color: $color-orange;
    }
    &--list {
      margin-left: 8px;
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 0.25px;
      color: $color-spanish_gray;
    }
  }
}

// .feed-card {
//   filter: drop-shadow(
//     0px 4px 4px rgba(0, 0, 0, 0.15)
//   ); // not box-shadow to have shadow in ticket dots

//   transition-duration: 0.2s;
//   transition-property: all;
//   transition-timing-function: ease-in-out;
//   transition-delay: 0s;

//   &--clickable {
//     &:hover,
//     &:active,
//     &:focus-visible {
//       cursor: pointer;
//       filter: drop-shadow(
//         0px 8px 8px rgba(0, 0, 0, 0.15)
//       ); // not box-shadow to have shadow in ticket dots

//       // transform: scale(1.01) translateY(-8px);
//       transform: translateY(-4px);
//     }
//   }

//   .feed-card__top {
//     border-top-left-radius: 8px;
//     border-top-right-radius: 8px;
//     border-bottom-left-radius: 8px;
//     border-bottom-right-radius: 8px;
//     background: $color-white;
//   }

//   .feed-card__top__cover {
//     position: relative;

//     .btn-show-list-stores {
//       display: flex;
//       align-items: center;

//       position: absolute;
//       right: 0;
//       bottom: -14px;

//       border-radius: 4px 0px 0px 4px;
//       padding: 6px 7px;
//       background-color: $color-green--middle;

//       span {
//         font-weight: 600;
//         font-size: 12px;
//         line-height: 16px;
//         display: flex;
//         align-items: center;
//         color: $color-white;

//         margin-right: 4px;
//       }

//       &:active {
//         transform: none;
//       }
//     }
//   }

//   .feed-card__top__cover__tags-container {
//     display: flex;
//     align-items: center;
//     padding-right: 14px;
//     width: 100%;

//     position: absolute;
//     left: 0;
//     bottom: 16px;

//     & > :not(:first-child) {
//       margin-left: 5px;
//     }
//   }

//   .feed-card__top__cover__merchant-tag {
//     background: rgba(0, 0, 0, 0.5);
//     border-radius: 4px;
//     padding: 2px 3px;

//     font-size: 12px;
//     font-weight: 500;
//     color: $color-white;
//     line-height: 16px;
//   }

//   .feed-card__top__cover__distance-tag {
//     display: flex;
//     align-items: center;

//     background: rgba(0, 0, 0, 0.5);
//     border-radius: 0px 4px 4px 0px;
//     padding: 2px 3px;
//     padding-right: 4px;

//     span {
//       font-weight: 500;
//       font-size: 12px;
//       line-height: 16px;
//       color: $color-white;

//       margin-left: 3px;
//     }
//   }

//   .feed-card__top__cover__cover-img {
//     display: block;
//     width: 100%;
//     height: 200px;
//     object-fit: cover;
//     border-top-left-radius: 8px;
//     border-top-right-radius: 8px;

//     background: url("../../../../assets/img/feed-img-default--border-radius-0.jpg");
//   }

//   .feed-card__top__cover__cover-img--darken {
//     filter: brightness(0.5);
//   }

//   .feed-card__top__info {
//     padding: 18px 12px 6px;

//     display: flex;
//     align-items: center;

//     .logo {
//       width: 50px;
//       height: 50px;
//       border-radius: 50%;
//       margin-right: 10px;
//       flex-shrink: 0;

//       align-self: flex-start;
//     }

//     .title {
//       font-weight: 600;
//       font-size: 14px;
//       line-height: 18px;
//       color: $color-black;
//     }

//     .nearest-store__icon {
//       flex-shrink: 0;
//       line-height: 12px;
//       margin-right: 4px;
//     }

//     .nearest-store__name {
//       font-weight: 400;
//       font-size: 12px;
//       letter-spacing: 0.2px;
//       color: $color-black--onyx;
//       line-height: 15px;
//     }

//     .statistic {
//       display: flex;
//       align-items: center;
//       flex-wrap: wrap;
//     }

//     .statistic__avg-rate {
//       display: flex;

//       .statistic__avg-rate__stars {
//         margin-left: 0 !important;
//         align-self: flex-start;
//         line-height: 12px;
//       }

//       span {
//         font-weight: normal;
//         font-size: 12px;
//         line-height: 16px;
//         letter-spacing: 0.2px;
//         color: $color-chinese_black;

//         margin-left: 4px;
//       }
//     }

//     .statistic__divider {
//       width: 1px;
//       height: 7px;
//       background-color: $color-grey_platinum;
//       border-left: 0.5px solid $color-grey_platinum;

//       margin: 0px 4.5px;
//     }

//     .statistic__avg-price {
//       display: flex;
//       align-items: baseline;
//       white-space: nowrap;

//       span {
//         font-weight: 500;
//         font-size: 12px;
//         line-height: 16px;
//         letter-spacing: 0.183333px;
//         color: $color-quick_silver;
//       }

//       .statistic__avg-price__price {
//         font-weight: 600;
//       }
//     }

//     .opening-time-info {
//       //
//       display: flex;
//       align-items: center;
//       flex-wrap: wrap;
//     }

//     .opening-time-info__status {
//       //
//       font-weight: 500;
//       font-size: 12px;
//       line-height: 20px;
//       letter-spacing: 0.2px;
//       color: $color-green_kelly;

//       &--closed {
//         color: $color-red--pigment;
//       }
//     }

//     .opening-time-info__comeback {
//       font-weight: 400;
//       font-size: 12px;
//       line-height: 20px;
//       letter-spacing: 0.2px;
//       color: $color-chinese_black;
//     }

//     .opening-time-info__comeback__time {
//       font-weight: 600;
//     }

//     .opening-time-info__divider {
//       width: 1px;
//       height: 12px;
//       background-color: $color-grey_platinum;
//       border-left: 0.5px solid $color-grey_platinum;

//       margin: 0px 4.5px;
//     }

//     .tags {
//       display: flex;
//       align-items: center;
//       margin-top: 4px;
//       overflow: hidden;
//       flex-wrap: wrap;
//       max-height: 16px;

//       .tag {
//         background: $color-corn-silk;
//         border-radius: 2px;
//         padding: 0px 8px;

//         font-weight: normal;
//         font-size: 12px;
//         line-height: 16px;
//         text-align: center;
//         letter-spacing: 0.15px;
//         color: $color-black;

//         white-space: nowrap;
//       }

//       & > :not(:first-child) {
//         margin-left: 4px;
//       }
//     }

//     .promotions {
//       margin-top: 2px;
//       padding-right: 4px;

//       display: flex;
//       align-items: center;
//       flex-wrap: wrap;

//       // 18px: 2 lines of items
//       // 8px: margin top of 1st line
//       // 6px: margin top of 2nd line
//       max-height: calc(2 * 18px + 2 * 6px);

//       overflow: hidden;

//       .promotion {
//         background: $color-white--floral;
//         margin-top: 6px;
//         padding: 1px 8px;
//         padding-right: 2px; // = 8px (original design) - 3px (border)  - 3px (border-image-outset)

//         // if use the line below (border-left), container must have 1px of padding (due to border-image-outset)
//         // border-left: 3px solid transparent; // 3px because each hole is 2x2(px), yellow stroke is 0.5px -> 3 to make sure image not be cut
//         border-right: 3px solid transparent; // 3px because each hole is 2x2(px), yellow stroke is 0.5px -> 3 to make sure image not be cut
//         border-image-source: url("../../../../assets/svg/border-tag-promotion.svg");
//         border-image-slice: 3; // keep adjust until reach the  best look
//         border-image-width: 3px; // keep adjust until reach the  best look
//         border-image-outset: 1;
//         border-image-repeat: round;
//         border-radius: 3px;

//         font-weight: normal;
//         font-size: 12px;
//         line-height: 16px;
//         color: $color-gray--gunmetal;

//         white-space: nowrap;
//       }

//       // * prepared
//       // .store-promotion--more {
//       //   padding: 1px 5px;
//       //   background: $color-ucla_gold;
//       //   border: 0.5px solid $color-ucla_gold;
//       //   border-radius: 3px;

//       //   font-weight: 500;
//       //   font-size: 12px;
//       //   line-height: 16px;
//       //   color: $color-white;
//       // }

//       & > :not(:last-child) {
//         margin-right: 8px;
//       }
//     }
//   }

//   .feed-card__bottom {
//     background: $color-white;
//     border-radius: 0 0 8px 8px;
//     padding: 6px 12px 14px;
//     // padding: 6px 0px 14px 12px; // dùng khi mở Voucher Tickets

//     & > :not(:first-child) {
//       margin-top: 8px;
//     }
//   }

//   .feed-card__bottom__description {
//     display: flex;
//     align-items: center;
//   }

//   .feed-card__bottom__description__icon {
//     flex-shrink: 0;
//     line-height: 12px;
//   }

//   .feed-card__bottom__description__content {
//     font-size: 12px;
//     font-weight: 400;
//     letter-spacing: 0.2px;
//     color: $color-chinese_black;
//     line-height: 16px;
//     margin-left: 8px;
//   }

//   .feed-card__bottom__prices--selling {
//     font-weight: 700;
//     font-size: 15px;
//     letter-spacing: 0.27px;
//     color: $color-orange;
//   }
//   .feed-card__bottom__prices--list {
//     margin-left: 8px;
//     font-weight: 500;
//     font-size: 13px;
//     letter-spacing: 0.25px;
//     color: $color-spanish_gray;
//   }
// }

// // *** Voucher tickets
// .voucher-tickets {
//   display: flex;
//   overflow-x: auto;

//   & > :not(:first-child) {
//     margin-left: 12px;
//   }

//   & :last-child {
//     margin-right: 12px;
//   }
// }

// .voucher-ticket {
//   background: $color-white--floral;
//   padding: 8px;
//   padding-right: 4px; // = 8px (design) - 4px (border)
//   width: 75vw;
//   max-width: 250px;

//   border-radius: 3px;
//   border-right: 4px solid transparent;
//   border-image-source: url("../../../../assets/svg/border-ticket.svg");
//   border-image-slice: 4;
//   border-image-width: 4px;
//   border-image-outset: 1;
//   border-image-repeat: round;

//   @media screen and (min-width: 576px) and (max-width: 640px) {
//     max-width: 220px;
//   }

//   @media screen and (min-width: 700px) {
//     max-width: 270px;
//   }

//   .voucher-ticket__name {
//     font-weight: normal;
//     font-size: 12px;
//     line-height: 16px;
//     color: $color-gray--gunmetal;

//     white-space: nowrap;
//   }

//   .voucher-ticket__prices {
//     margin-top: 2px;

//     display: flex;
//     align-items: baseline;

//     .voucher-ticket__prices__curr-price {
//       font-weight: bold;
//       font-size: 12px;
//       line-height: 14px;
//       letter-spacing: 0.266667px;
//       color: $color-orange;

//       margin-right: 9px;
//     }

//     .voucher-ticket__prices__old-price {
//       font-weight: 500;
//       font-size: 11px;
//       line-height: 12px;
//       letter-spacing: 0.25px;
//       color: $color-spanish_gray;
//     }
//   }
// }
