a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 1rem;

  background: rgba(0, 0, 0, 0.1);

  & > img {
    margin-right: 1rem;
    box-shadow: 0px 4px 15px rgba(165, 165, 165, 0.25);
  }
}

.bottom-bar__direction-btn {
  border: 0;
  background: linear-gradient(220.97deg, #ffd300 -5.25%, #fa8b51 147.32%);
  border-radius: 20.5px;

  float: right;
  margin-left: auto;
  padding: 10px 24px;

  span {
    color: white;
    font-weight: 700;
    font-size: 0.875rem;
  }
}

.info-window {
  max-width: 55vw;

  padding: 6px;
  background: white;
  border-radius: 5px;

  position: relative;

  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.102);

  transform: translate(-50%, -100%);
}

.info-window__store {
  transform: translate(-50%, calc(-100% - 44px));
}

.info-window__device {
  transform: translate(-50%, calc(-100% - 30px));
}

.info-window__delivery {
  transform: translate(-50%, calc(-100% - 30px));
}

.info-window::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;

  -webkit-transform: translateX(-50%) translateY(50%);
  transform: translateX(-50%) translateY(100%);

  width: 0px;
  height: 0px;

  border-style: solid;
  border-width: 5px 5px 0px 5px; // not show border bottom
  border-color: white transparent; // border left and right is transparent
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.102);
}

.info-window__title {
  font-weight: 600;
  font-size: 10px;
  color: #ffd300;
}

.info-window__content {
  font-weight: 400;
  font-size: 10px;
  color: #151515;
}

.bubble-address {
  position: absolute;
  top: 50%;
  left: 50%;
}
