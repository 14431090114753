@import "scss/variables.scss";
@import "scss/mixins.scss";

.header-bar-container {
  background: url("../../../assets/svg/header-pc-bg.svg") no-repeat; // TODO: @Quyen thay sang .PNG
  background-size: cover;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  .header-content {
    display: flex;
    align-items: center;
    padding: 11px 20px;

    .logoUtop {
      flex-shrink: 0;
      width: 61px;
      height: 30px;
    }
  }
}

.header-search-bar {
  width: 100%;
  margin-left: 20px;
  flex: 1 1 450px;
}

.iconSearchHome {
  margin-right: 6px;
  width: 14px;
  height: 14px;
}

.searchInput {
  display: flex;
  align-items: center;

  padding: 9px 8px;
  width: 100%;
  height: 34px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(187, 187, 187, 0.25);
  border-radius: 30px;

  input {
    padding: 0;
    height: auto;
    width: 100%;
    outline: none;
    border: none;

    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: $color-black;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:focus {
      border: none;
      outline: none;
    }
  }

  ::placeholder {
    color: $color-warm-grey;
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder {
    color: $color-warm-grey;
  }
  ::-ms-input-placeholder {
    color: $color-warm-grey;
  }
}

.searchBar {
  position: absolute;
  border-radius: 0px 8px 8px 0px;
  height: 35px;
  margin-left: -34px;
}
.searchIcon {
  position: absolute;
  margin: 10.5px;
  margin-left: -25px;
}

.btn-login {
  outline: none;
  border: none;
  box-shadow: none;

  background: linear-gradient(220.97deg, #ffd300 -5.25%, #fa8b51 147.32%);
  border-radius: 30px;
  padding: 8px 12px 7px;
  margin-left: 20px;
  line-height: 0;

  span {
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    white-space: nowrap;
    line-height: 19px;
  }

  @include maxW(799px) {
    padding: 9px 13px;
    margin-left: 10px;
    line-height: 0;

    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.has-headerBgr {
  .title-header {
    color: #151515;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.3px;
  }
}

.avatarUser {
  display: block;
  flex-shrink: 0;
  border-radius: 50%;
  width: 34px;
  height: 34px;
}

.drop-down-ava {
  flex-shrink: 0;
}

.dropdownMenu {
  transform: translate3d(0px, 40px, 0px) !important;
}

@include minW(800px) {
  .searchInput {
    padding: 9px 16px;
    input {
      font-size: 14px;
      line-height: 19px;
    }
    .input-mobi {
      display: none;
    }
  }

  .drop-down-ava {
    margin: 0;
    padding: 0;
    margin-left: 20px;

    display: flex;

    .c-header-nav-link {
      margin: 0;
      padding: 0;
      box-shadow: none;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .user-name {
      @include font(500, 14px, $color-blackcl);
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      word-break: break-word;
      line-height: 19px;
      max-width: 14ch;
    }
    .arrow-dropdown {
      margin-left: 10px;
    }
  }
  .btn {
    &:focus {
      box-shadow: none;
    }
  }
}

@include maxW(310px) {
  .drop-down-ava,
  .header-search-bar {
    margin-left: 12px !important;
  }
}

// <=799px
@include maxW(799px) {
  .drop-down-ava {
    display: flex;
    margin-left: 10px;

    .c-header-nav-link {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0px;
      padding: 0px;

      .user-name {
        display: none;
      }
      .arrow-dropdown {
        display: none;
      }
    }
  }

  .header-bar-container {
    .header-content {
      padding: 11px;
      padding-left: 13px;
      padding-right: 16px;

      .logoUtop {
        width: 59px;
        height: 27px;
      }
    }
  }
}

@include minW(800px) {
  .iconSearchHome {
    margin-right: 11px;
    width: 16px;
    height: 16px;
  }

  .header-search-bar {
    margin-left: 30px;
  }
}
.c-header-nav .c-header-nav-link,
.c-header-nav .c-header-nav-btn {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

@media only screen and (max-width: 280px) {
  input {
    font-size: 10px;
  }
}
@include mobile {
  .header-bar-container {
    top: 0;
    left: 0;
  }
}
