@import "scss/variables";
@import "scss/mixins";

// my order page
.order-page {
  background: $bgr-app;
  min-height: 100vh;
  position: relative;

  .order-page__bottom {
    padding: 1rem;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    .btn-continue-payment {
      display: block;
      width: 100%;

      margin-left: auto;
      margin-right: auto;
      padding-top: 10px;
      padding-bottom: 10px;

      font-size: 14px;
      font-weight: 700;
      color: #fff;

      border-radius: 20.5px;
      outline: 0;
      border: 0;

      background: linear-gradient(243.97deg, #ffd300 27.74%, #fa8b51 143.54%);

      &:disabled {
        background: #d2d2d2;
      }
    }
  }

  hr {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  // icons
  .icon-garbage_can {
    @include imgIcon(12px, 12px, "../img/garbage-can.svg");
    margin-right: 5px;
  }

  .icon-notepad {
    @include imgIcon(16.5px, 22px, "../img/notepad.svg");
    margin-right: 14.5px;
  }

  .order-wrapper {
    margin-top: 12px;
    margin-left: 8px;
    margin-right: 8px;
    padding-bottom: 73px;

    .header-info_wrapper {
      background-color: white;
      border-radius: 8px;
      margin-bottom: 12px;

      .type-delivery {
        @include midFlex(space-between, center);
        padding: 10px;
        border-bottom: solid 1px #ebedef;
        .type-delivery_left {
          @include midFlex(center, center);
          .icon-delivery {
            width: 40px;
          }
          .text-delivery {
            font-weight: 600;
            color: black;
          }
        }
      }

      .type-delivery-input {
        @include midFlex(space-between, center);
        padding: 10px;
        .address-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .type-delivery_left {
          @include midFlex(center, center);
          .icon-delivery {
            width: 40px;
          }
          .text-delivery {
            font-weight: 600;
            color: black;
          }
        }
        .line-gray {
          margin: 10px 0;
          width: 100%;
          height: 1px;
          background: #ebedef;
        }
      }
    }

    .shipping-content {
      .shipping-fee {
        @include midFlex(space-between, center);
        .shipping-fee_title {
          color: black;
          font-weight: 500;
        }
      }
      .shipping-fee-note {
        @include midFlex(space-between, center);
        .shipping-fee-note_title {
          display: flex;
          .shipping-fee-note_title-text {
            color: rgb(88, 171, 0);
            font-weight: 600;
            margin-right: 5px;
          }
        }
        .shipping-fee-note_title-value-text {
          color: rgb(88, 171, 0);
          font-weight: 600;
        }
      }
      .shipping-description {
        .shipping-description-text {
          background-color: rgb(255, 249, 234);
          color: rgb(250, 100, 0);
          padding: 0 5px;
          font-size: 12px;
        }
      }
    }

    .section-bill {
      background-color: #ffffff;
      border-radius: 7px;
      padding: 12px;
      margin-bottom: 0.5rem;

      .divider-line {
        border-top: 0.8px solid #dedede;
      }
      .divider-line--expand {
        margin-left: -12px;
        margin-right: -12px;
      }

      // buttons
      .button-add-more-food {
        border: none;

        background-color: $color-light_orange;

        width: 100%;
        height: 40px;
        padding: 0.5rem;

        border-radius: 7px;

        @include font(600, 14px, #000000);

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .note-for-store__container {
        display: flex;
        align-items: center;

        // border-top: 0.8px solid #dedede;
        // border-bottom: 0.8px solid #dedede;
      }

      .food-list-section__container {
        padding-bottom: 16px;
      }

      .total-price {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-top: 14px;
        padding-bottom: 14px;

        font-weight: 600;
        font-size: 14px;
        color: $color-black;

        .total-price__label {
          font-weight: 500;
        }
      }
    }
  }
}

.list-delivery-discount {
  max-height: 30vh;
  overflow: scroll;

  padding-left: 20px;
  padding-right: 17px;
  padding-bottom: 32px;

  & > * {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  & > :not(:first-child) {
    border-top: 0.5px solid #dedede;
  }
}

.__border-block {
  border-block: 0.8px solid #dedede;
}

.__border-top {
  border-top: 0.8px solid #dedede;
}

.note-for-store__input__placeholder {
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 14px;
    font-weight: 400;

    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #999999;

    font-size: 14px;
    font-weight: 400;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #999999;

    font-size: 14px;
    font-weight: 400;
  }
}
