@import "scss/variables.scss";
@import "scss/mixins.scss";
.page-header-container {
  height: 56px;

  .page-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2; // ! 1-> 2: page myvoucher scroll che mất header(QuyenVTB)

    .page-header__main {
      position: relative;
      display: flex;
      height: 56px;
      padding: 0 16px;
      align-items: center;
      justify-content: center;
      background-color: #ffca26;
      background: url("../../../../assets/img/bgr-store-header.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      .page-header__main__btn-left {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);

        cursor: pointer;
      }

      & > h2 {
        color: #151515;
        font-size: 18px;
        margin-bottom: 0;
        font-weight: 600;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.3px;

        width: 75vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (max-width: 280px) {
          font-size: 15px;
        }
      }

      .page-header__main__btn-right {
        position: absolute;
        top: 50%;
        right: 14px;
        color: #000;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        text-align: right;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
}
