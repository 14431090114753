.address-option {
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 7px;

  padding: 8px 12px;

  font-size: 12px;

  .address-option__title {
    font-weight: 500;
    color: #151515;
  }

  .address-option__detail {
    font-weight: 400;
    color: #151515;
  }

  .address-option__description {
    font-weight: 400;
    color: #717171;
  }
}

.address-option--active {
  background: #fff6de;
  border: 1px solid #ffb600;
}
