@import "scss/mixins";

.input-wrapper {
  .header-input-table {
    @include midFlex(space-between, center);
    padding: 0 10px;
    .title-input-table {
      color: black;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .input-table-content {
    min-height: 350px;
    border-top: solid 1px #e4e4e4;

    .input-table {
      font-size: 14px;
      margin: 5px 15px;
    }
    .input-table ::placeholder {
      font-size: 16px;
    }
  }
}
